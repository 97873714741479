import React, { useEffect, useState } from "react";
import { https as makeHttps } from "../../auth/AuthUser";
import { Link, useNavigate } from 'react-router-dom';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import modal from "../../img/modal/modal.png";
import { parsePhoneNumberFromString } from 'libphonenumber-js';


const LoginModal = () => {


  const referId = localStorage.getItem('referId') || "";

  const [isOpenForm, setIsOpenForm] = useState(false);


  const [registerStatus, setRegisterStatus] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [name, setName] = useState('');
  const [emails, setEmails] = useState('');
  const [dob, setDob] = useState('');
  const [phone, setPhone] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [accept, setAccept] = useState('');
  const [user_type, setUserType] = useState(2);
  const [refered_by, setReferedBy] = useState(referId);
  // const [refer_id , setReferId] = useState('');


  const [namee, setNamee] = useState('');
  const [emaile, setEmaile] = useState('');
  const [dobe, setDobe] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [passworde1, setPassworde1] = useState('');
  const [passworde2, setPassworde2] = useState('');
  const [loginerror, setLoginerror] = useState('');
  const [accepterror, setAccepterror] = useState('');
  // const [user_type_error, setUserTypeError] = useState('');


  const navigate = useNavigate();
  const toggleForm = () => {
    setIsOpenForm(!isOpenForm);
  };

  const http = makeHttps();


  const handleLogin = async () => {
    try {
      const response = await http.post('user/login', {
        email: email,
        password: password,
      });

      if (response.data) {
        const token = response.data.user.token;
        localStorage.setItem('token', JSON.stringify(token))
        localStorage.setItem('user', JSON.stringify(response.data.user))
        //navigate('/');
        window.location.href = './';
      }

    } catch (error) {
      // Log the error to the console for debugging
      console.error('Error during login:', error);
      setLoginerror(error.response.data.message);
    }
  };



  const handleRegister = async () => {

    try {
      setAccepterror("");
      if (accept) {
        const responses = await http.post('user/register', {
          full_name: name,
          email: emails,
          phone: phone,
          dob: dob,
          password: password1,
          confirm_password: password2,
          // user_type: user_type,
          refered_by: refered_by,
          photo: '',
        });

        if (responses.data && responses.data.status === 1) {

          setRegisterStatus(responses.data.status);

        } else {

          // console.error('Error during Register:', responses.data.message);

          setNamee('');
          setEmaile('');
          setPhoneError('');
          setDobe('');
          setPassworde1('');
          setPassworde2('');
          // setUserTypeError('');
          setLoginerror('');




          if (Array.isArray(responses.data.message)) {
            responses.data.message.map((category) => {

              if (category.param === "full_name") {
                setNamee(category.msg);

              }
              if (category.param === "email") {
                setEmaile(category.msg);
              }
              if (category.param === "phone") {
                setPhoneError(category.msg);
              }
              if (category.param === "dob") {
                setDobe(category.msg);
              }
              if (category.param === "password") {
                setPassworde1(category.msg);
              }
              if (category.param === "confirm_password") {
                setPassworde2(category.msg);
              }
              // if (category.param === "user_type") {
              //   setUserTypeError(category.msg);
              // }


            });


          } else {
            setNamee('');
            setEmaile('');
            setDobe('');
            setPhoneError('');
            setPassworde1('');
            setPassworde2('');
            // setUserTypeError('');

            if (responses.data.message === 'User exists') {
              setEmaile(responses.data.message);
            } else {

              setLoginerror(responses.data.message);
              console.error(responses.data.message);
              // alert(responses.data.message);
            }
          }
        }
      } else {
        setAccepterror("Please Accept our Privacy Policy.");
      }
    }
    catch (error) {
      // Log the error to the console for debugging
      console.error('Error during Register:', error);
      // Display the server's error message to the user
      // alert(`Login failed. ${error.response.data.message}`);
    }
  };



  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [showPassword2, setShowPassword2] = useState(false);

  const handlePasswordVisibility2 = () => {
    setShowPassword2((prevShowPassword2) => !prevShowPassword2);
  };

  const validatePhone = () => {
    try {
      const phoneRegex = /^[0-9]+$/; // Updated regex to allow one or more digits
      if (!phoneRegex.test(phone)) {
        setPhoneError('Please enter a valid phone number');
      } else {
        setPhoneError('');
      }
    } catch (error) {
      setPhoneError('Error validating phone number');
    }
  };



  return (
    <div
      className="modal register__modal"
      id="signUpPin"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="row align-items-center g-4">
                <div className="align-items-cener col-lg-6 d-flex justify-content-center">
                  <img src={modal} alt="Sign Up" className="img-fluid" />
                </div>
                <div className="col-lg-6 modal__right__outer">
                  <div className="modal__right">
                    <h2 className="text-black text-center mb-4">Sign Up</h2>
                    <div className="tab-content" id="myTabContent2">

                      <div
                        className="tab-pane fade show active"
                        id="contact2"
                        role="tabpanel"
                      >
                        <div className="form__tabs__wrap">
                          {(registerStatus === 1) ?
                            <div className="text-center">
                              <div className="success-ico"><span className="bi bi-check-circle"></span></div>
                              <h5 className="text-success">Registration Successfull.</h5>
                              <p>Please proceed to the sign-in page to verify your account and log in.</p>
                              {/* <p>
                                <Link href="URL:void(0)" className="secondary-color" ></Link>
                              </p> */}
                              <Link className="cmn--btn" style={{ padding: '10px 20px' }} href="URL:void(0)" data-bs-dismiss="modal" data-bs-toggle="modal"
                                data-bs-target="#signInPin">
                                <span>Sign In</span>
                              </Link>
                            </div>
                            :

                            <form action="#0">
                              {/* {referId ?
                            <div className="form__grp">
                              <label htmlFor="name34">Refer Id</label>
                              <input
                                    type="text" value={referId} onChange={(e) => setReferedBy(e.target.value)}
                                placeholder="Enter Refer Id *"
                              />
                              <div className="text-danger">{namee}</div>
                            </div>:
                                <div className="form__grp">
                                  <label htmlFor="name34">Account Type</label>
                                <select
                                  className="form-select"
                                  id="floatingSelect"
                                  aria-label="Floating label select example"
                                  value={user_type}
                                  onChange={(e) => setUserType(e.target.value)}
                                >
                                  <option value="">-- Select Account Type --</option>
                                  <option value="1">Mentor</option>
                                  <option value="2">Individual</option>

                                </select>
                                  <div className="text-danger">{user_type_error}</div>
                                </div>
                            } */}
                              <div className="form__grp">
                                <label htmlFor="name34">Name</label>
                                <input
                                  type="text" value={name} onChange={(e) => setName(e.target.value)}
                                  placeholder="Enter Your Name *"
                                />
                                <div className="text-danger">{namee}</div>
                              </div>
                              <div className="form__grp">
                                <label htmlFor="phone34">Phone</label>
                                <input
                                  type="tel" value={phone} onChange={(e) => setPhone(e.target.value)}
                                  onBlur={validatePhone}
                                  placeholder="Enter Your Phone *"
                                />
                                <div className="text-danger">{phoneError}</div>
                              </div>
                              <div className="form__grp">
                                <label htmlFor="email34">Email</label>
                                <input
                                  type="email" value={emails} onChange={(e) => setEmails(e.target.value)}
                                  placeholder="user@mail.com"
                                />
                                <div className="text-danger">{emaile}</div>
                              </div>

                              {/* <div className="form__grp">
                                <label htmlFor="agegrp34">DOB</label>
                                <input
                                  type="date" value={dob} onChange={(e) => setDob(e.target.value)}
                                  placeholder="Date of Birth"
                                />
                                <div className="text-danger">{dobe}</div>
                              </div> */}
                              <div className="form__grp">
                                <label htmlFor="agegrp34">DOB</label>
                                <input
                                  type="date"
                                  value={dob}
                                  onChange={(e) => setDob(e.target.value)}
                                  placeholder="Date of Birth"
                                  max={new Date().toISOString().split("T")[0]}  // Disables future dates
                                />
                                <div className="text-danger">{dobe}</div>
                              </div>

                              <div className="form__grp">
                                <label htmlFor="toggle-password10">
                                  Password
                                </label>
                                <input
                                  id="toggle-password10"
                                  type={showPassword ? 'text' : 'password'} value={password1} onChange={(e) => setPassword1(e.target.value)}
                                  placeholder="Enter Your Password"
                                />

                                <a
                                  className="field-icon toggle-password9"
                                  type="button"
                                  onClick={handlePasswordVisibility}
                                >
                                  {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                </a>


                                <div className="text-danger">{passworde1}</div>
                              </div>
                              <div className="form__grp">
                                <label htmlFor="toggle-password11">
                                  Confirm Password
                                </label>
                                <input
                                  id="toggle-password11"
                                  type='password' value={password2} onChange={(e) => setPassword2(e.target.value)}
                                  placeholder="Confirm Password"
                                />

                                <div className="text-danger">{passworde2}</div>

                                <div className="text-danger">{loginerror}</div>
                              </div>


                              <div className="form-check">
                                <input className="form-check-input border-dark" type="checkbox" checked={accept} onClick={(e) => setAccept(accept ? "" : e.target.value)} value="1" id="RememberMe" required name='RememberMe' />
                                <label className="form-check-label" for="RememberMe">
                                  <small>Accept our <Link to="/privacy-policy" className="text-theme"><span data-bs-dismiss="modal" >Privacy Policy</span></Link></small>
                                </label>
                              </div>
                              <div className="text-danger">{accepterror}</div>

                              <div className="create__btn">
                                <Link href="URL:void(0)" onClick={handleRegister} className="cmn--btn">
                                  <span>Sign Up</span>
                                </Link>
                              </div>
                              <p>
                                Already have an account?{" "}
                                <Link href="URL:void(0)" data-bs-dismiss="modal" data-bs-toggle="modal"
                                  data-bs-target="#signInPin">Sign In</Link>
                              </p>
                            </form>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
