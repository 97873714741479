import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Includes/Sidebar";
import Header from "../Components/Includes/Header";
import SidebarIcon from "../Components/Includes/SidebarIcon";
function ContactUs() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const validateForm = () => {
        const { name, email, phone, message } = formData;
        const newErrors = {};

        if (!name) newErrors.name = 'Name is required';
        if (!email) newErrors.email = 'Email is required';
        if (!phone) newErrors.phone = 'Phone is required';
        if (!message) newErrors.message = 'Message is required';

        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length === 0) {
            setSubmitted(true);
            setErrors({});
            // Submit form logic here (API call, etc.)
            console.log("Form submitted successfully", formData);
        } else {
            setErrors(validationErrors);
        }
    };

    // sidebar
    const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(280);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
        setSidebarCollapsed(!isSidebarCollapsed);
        setSidebarWidth(isSidebarCollapsed ? 280 : 80); // Toggle sidebar width
    };
    return (
        <>
            <Header toggleSidebar={toggleSidebar} />
            <div className="col-xxl-2 col-xl-3 col-lg-3 display991" style={{ width: `${sidebarWidth}px` }}>
                {sidebarOpen ? (
                    <SidebarIcon onClick={toggleSidebar} />
                ) : (
                    <Sidebar onClick={toggleSidebar} />
                )}
            </div>
            <div className="col-xxl-9 col-xl-9 col-lg-9" style={{ width: isSidebarCollapsed ? '1266px' : 'calc(100% - 280px)' }}>
                <div className="content-registration pt-3 bg-dark ">
                    <div className="container">
                        <div className="p-1">
                            <div className="p-1">
                                <div className='row g-3' >
                                    <div className="col-lg-12">
                                        <h2 className="mb-2">Contact Us</h2>
                                        <p>Welcome to KB TUBE's Contact Page. If you have any questions, feedback, or concerns regarding our video streaming application, please feel free to reach out to us using the following channels:</p>
                                    </div>
                                    <div className="col-lg-12">
                                        {submitted ? (
                                            <div className="alert alert-success">
                                                Thank you! Your inquiry has been submitted.
                                            </div>
                                        ) : (
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="name" className="form-label"><strong>Name</strong></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name"
                                                                name="name"
                                                                value={formData.name}
                                                                onChange={handleChange}
                                                            />
                                                            {errors.name && <div className="text-danger">{errors.name}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="email" className="form-label"><strong>Email</strong></label>
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                id="email"
                                                                name="email"
                                                                value={formData.email}
                                                                onChange={handleChange}
                                                            />
                                                            {errors.email && <div className="text-danger">{errors.email}</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="phone" className="form-label"><strong>Phone</strong></label>
                                                        <input
                                                            type="tel"
                                                            className="form-control"
                                                            id="phone"
                                                            name="phone"
                                                            value={formData.phone}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.phone && <div className="text-danger">{errors.phone}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="message" className="form-label"><strong>Message</strong></label>
                                                        <textarea
                                                            className="form-control"
                                                            id="message"
                                                            name="message"
                                                            rows="4"
                                                            value={formData.message}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.message && <div className="text-danger">{errors.message}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <button type="submit" className="btn btn-primary mb-5">Submit</button>
                                                </div>
                                            </form>
                                        )}
                                    </div>
                                    <div className="col-lg-12">
                                        <ol>
                                            <li><strong>Address:</strong> If you prefer traditional mail correspondence, you can send letters or packages to our headquarters at the following address:<br />
                                                KB TUBE Headquarters<br />
                                                4300 Biscayne Blvd Suite 203 Miami,<br />
                                                Florida 33137, US</li>

                                            <li><strong>Email:</strong> You can contact us via email at <a href="mailto:contact@kbtube.com">contact@kbtube.com</a> for general inquiries, partnership opportunities, or media inquiries. We strive to respond to all emails within 24-48 hours.</li>
                                            <li><strong>Contact Number:</strong> <a href="tel:+1 305-317-1806">+1 305-317-1806</a></li>

                                        </ol>
                                        <p>Thank you for choosing KB TUBE. We appreciate your support and look forward to serving you better.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ContactUs