import React from "react";
import { Link } from 'react-router-dom';
import Header from "../Components/Includes/Header";
// import { FaCheckCircle } from 'react-icons/fa'; // Importing an icon?

function ThankYou() {
    return (
        <>
            <Header />
            <div className="content-registration bg-dark text-white py-5">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6">
                            <div className="content-top mb-4">
                                <h2 className="fw-bolder text-center text-white">
                                    <span>Thank You!</span>
                                </h2>
                            </div>
                            <div className="rounded-4 shadow-lg bg-dark p-4 text-center">
                                <i className="bi bi-check-circle-fill mb-3" style={{color:'green', fontSize:'80px'}}></i>
                                <h2 className="mb-3 fw-bold">Your video has been uploaded</h2>
                                <p className="text-muted mb-4">It's currently pending approval. You will be notified once it's processed.</p>
                                
                                <Link to="/channel/" className="btn btn-secondary btn-lg w-100">
                                    Go to Channel
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ThankYou;
