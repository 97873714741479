import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../Components/Includes/Sidebar';
import img3 from '../../assets/images/thumnail-3.jpg';
import profilePic from "../../assets/images/profile-pic.jpg";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import video1 from '../../assets/videos/video-1.mp4';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { authHttps } from "../../auth/AuthUser";
import { formatDistanceToNow } from 'date-fns';
import { Link } from 'react-router-dom';
import VideoListLoading from '../../Components/LoadingPages/VideoListLoading';
import { ShortsRoute, VideoRoute } from '../../utils/routeUtils';
import VideosNotFound from '../../Components/ErrorPages/VideosNotFound';
import ShortsListingLoading from '../../Components/LoadingPages/ShortsListingLoading';
import Header from '../../Components/Includes/Header';
import SidebarIcon from '../../Components/Includes/SidebarIcon';
const TimeAgo = ({ date }) => {
   // Assuming `date` is a string in the format 'YYYY-MM-DDTHH:mm:ss.sssZ'
   const parsedDate = new Date(date);

   const timeAgo = formatDistanceToNow(parsedDate, { addSuffix: true });

   return <span>{timeAgo}</span>;
};


const formatViewsCount = (count) => {
   if (count < 1000) {
      return count.toString();
   } else if (count < 1000000) {
      return `${(count / 1000).toFixed(1)}K`;
   } else {
      return `${(count / 1000000).toFixed(1)}M`;
   }
};

function Subscriptions() {
   const http = authHttps();

   const [allVideo, setallVideo] = useState([]);
   const [loadingvideos, setloadingvideos] = useState(true);

   const [allVideop, setallVideop] = useState([]);
   const [loadingvideosp, setloadingvideosp] = useState(true);

   const navigate = useNavigate();
   const [photo, setPhoto] = useState('');

   
    // Sidebar states
    const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(280);
    const [sidebarOpen, setSidebarOpen] = useState(false);

   const viewVideo = async (videoId) => {
      await http.post('user/addView', { 'videoId': videoId });
   };



   // const getVideo = async (categoryId) => {
   //    try {
   //       const response = await http.post('user/categoryWiseVideo', { 'categoryId': categoryId });
   //       if (response.data && response.data.data) {
   //          setallVideo(response.data.data);
   //          setPhoto(response.data.data.photo);
   //       } else {
   //          console.error('Invalid data structure received from the API:', response.data);
   //       }
   //    } catch (error) {
   //       console.error('Error fetching video categories:', error);
   //    } finally {
   //       setloadingvideos(false);
   //    }
   // };

   const fetchVideo = async () => {
      try {
         setloadingvideos(true); // Set loading to true when starting the fetch


         const response = await http.post('user/subscriptions', { type: 1 });
         if (response.data && response.data.data) {
            setallVideo(response.data.data);
            // console.log(response.data.data);
         } else {
            console.error('Invalid data structure received from the API:', response.data);
         }
      } catch (error) {
         console.error('Error fetching video categories:', error);
      } finally {
         setloadingvideos(false); // Set loading to false regardless of success or error
      }
   };

   useEffect(() => {
      fetchVideo();
   }, []); // Run the effect only once on component mount

   const fetchVideop = async () => {
      try {
         setloadingvideosp(true); // Set loading to true when starting the fetch


         const response = await http.post('user/subscriptions',{type: 2});
         if (response.data && response.data.data) {
            setallVideop(response.data.data);
            // console.log(response.data.data);
         } else {
            console.error('Invalid data structure received from the API:', response.data);
         }
      } catch (error) {
         console.error('Error fetching video categories:', error);
      } finally {
         setloadingvideosp(false); // Set loading to false regardless of success or error
      }
   };

   useEffect(() => {
      fetchVideop();
   }, []); // Run the effect only once on component mount



   const [videoCategories, setVideoCategories] = useState([]);
   const [loadingCategories, setLoadingCategories] = useState(true);

   useEffect(() => {
      // Fetch video categories
      const fetchVideoCategories = async () => {
         try {
            const response = await http.post('user/videoCategory');

            if (response.data) {
               // Make sure response.data is an array before setting it to state
               setVideoCategories(response.data.data);
            } else {
               console.error('Invalid data structure received from the API:', response.data);
            }
         } catch (error) {
            console.error('Error fetching video categories:', error);
         } finally {
            setLoadingCategories(false);
         }
      };

      fetchVideoCategories();
   }, []);

   // Toggle Sidebar
   const toggleSidebar = () => {
      setSidebarOpen(!sidebarOpen);
      setSidebarCollapsed(!isSidebarCollapsed);
      setSidebarWidth(isSidebarCollapsed ? 280 : 80); // Toggle sidebar width
  };

   return (
      <>
          <Header toggleSidebar={toggleSidebar} />
            <div className="col-xxl-2 col-xl-3 col-lg-3 display991" style={{ width: `${sidebarWidth}px` }}>
                {sidebarOpen ? (
                    <SidebarIcon onClick={toggleSidebar} />
                ) : (
                    <Sidebar onClick={toggleSidebar} />
                )}
            </div>
            <div className="col-xxl-9 col-xl-9 col-lg-9" style={{ width: isSidebarCollapsed ? '1266px' : 'calc(100% - 280px)' }}>

            <div className="main__body__wrap left__right__space pb-60 pt-20">
            <div className='d-flex justify-content-between align-items-center px-1'>
                <h2 className='h3'>Latest</h2>
                <Link to="/feed/channels" className='text-decoration-none'><strong>Manage</strong></Link>
            </div>
            <div className='row g-3 row-cols-lg-3 row-cols-xl-4 row-cols-md-2 row-cols-sm-2'>

               {loadingvideos ? (
                  <VideoListLoading />
               ) : allVideo.length > 0 ? (
                     allVideo.map((video, index) => (
                        <div className='col'>
                           <div className='video-box-single-v with-action' onClick={() => viewVideo(video.videoId)}>

                              <Card className='shadow-none h-194-video'>

                                 <div className='video-box cursor-pointer'>
                                    <Link to={VideoRoute(video.uniqId)}>
                                       {/*--<span className='duration-time'>11:11:11</span>--*/}
                                       <div>
                                          <CardMedia
                                             component="video"
                                             alt="Video Poster"
                                             poster={video.thumbnail}
                                             className='w-100'
                                             muted
                                          >
                                             <source src={video.url} className='w-100' type="video/mp4" />
                                          </CardMedia>
                                       </div>

                                    </Link>
                                 </div>
                                 <CardContent>
                                    <div className='content-box'>
                                       <div className='row align-items-start g-0'>
                                          <div class="col-3">
                                             <Link to={'/@' + video.handel}>
                                                <div className='channel-thumbnail'>

                                                   {
                                                      video.channelIcon ? (
                                                         <img src={video.channelIcon} className="rounded-circle  img-fluid" />
                                                      ) : (
                                                         <img src={profilePic} className="rounded-circle  img-fluid" />
                                                      )}
                                                </div>
                                             </Link>
                                          </div>
                                          <div className='col-8'>
                                             <Typography variant="h5" component="div" className='v-tiltle'>
                                                {/* <Link href='/watch'>{video.title}</Link> */}

                                                <Link to={VideoRoute(video.uniqId)}> {video.title}</Link>
                                             </Typography>
                                             <Typography variant="body2" color="text.secondary">
                                                <Link to={'/@' + video.handel} className='channel-name mt-2'>{video.channelName}</Link>
                                                <Link to={VideoRoute(video.uniqId)} className='views-time'>{formatViewsCount(video.view)} views <span>.</span> {<TimeAgo date={video.createdAt} />}</Link>
                                             </Typography>
                                          </div>
                                          <div className='col-1'>
                                             <div className="dropdown text-end">
                                                <a href="#" className="d-block caret-none  text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                   <i class="bi bi-three-dots-vertical"></i>
                                                </a>
                                                <ul className="dropdown-menu">
                                                   <li><Link className="dropdown-item" href="/login"><i class="bi bi-bar-chart-steps me-3"></i>Add to queue</Link></li>
                                                   <li><Link className="dropdown-item" href="/login"><i className="me-3 bi bi-clock-history"></i> Save to Watch Later</Link></li>
                                                   <li><Link className="dropdown-item" href="/login"><i class="bi bi-plus-square me-3"></i> Save to Playlist</Link></li>
                                                   <li><Link className="dropdown-item" href="/login"><i class="bi bi-download me-3"></i> Download</Link></li>
                                                   <li><Link className="dropdown-item" href="/login"><i className="me-3 bi bi-share"></i> Share</Link></li>
                                                   <li><hr className="dropdown-divider" /></li>
                                                   <li><Link className="dropdown-item" href="#"><i className="me-3 bi bi-ban"></i>  Not Interested </Link></li>
                                                   <li><Link className="dropdown-item" href="#"><i class="bi bi-dash-circle me-3"></i>  Don't Recomended Channel </Link></li>
                                                   <li><Link className="dropdown-item" href="#"><i className="me-3 bi bi-flag"></i>  Report </Link></li>
                                                </ul>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </CardContent>
                              </Card>
                           </div>
                        </div>
                     ))
               ) : (
                  <VideosNotFound />
               )}


            </div>
            <h2 className='h4 mt-3'><i class="bi bi-file-play text-theme"></i> Shorts</h2>
            <div className='row row-cols-lg-5 row-cols-md-3 row-cols-sm-6'>
               {loadingvideosp ? (
                  <ShortsListingLoading />
               ) : allVideop.length > 0 ? (
                  allVideop.map((video, index) => (
                     <div className='col'>
                        <div className='video-box-single-v with-action' onClick={() => viewVideo(video.videoId)}>
                           <Card className='shorts-card shadow-none'>
                              <div className='video-box cursor-pointer'>

                                 <Link to={ShortsRoute(video.uniqId)}>
                                    {/*--<span className='duration-time'>11:11:11</span>--*/}
                                    <div>
                                       <CardMedia
                                          component="video"
                                          alt="Video Poster"
                                          poster={video.thumbnail}
                                          className='w-100'
                                          muted
                                       // controls
                                       >
                                          <source src={video.url} className='w-100' type="video/mp4" />
                                       </CardMedia>
                                    </div>
                                 </Link>
                              </div>
                              <CardContent>
                                 <div className='content-box'>
                                    <div className='row align-items-start g-0'>

                                       <div className='col-11'>
                                          <Typography variant="h5" component="div" className='v-tiltle'>
                                             {/* <Link href='/watch'>{video.title}</Link> */}

                                             <Link to={ShortsRoute(video.uniqId)}>{video.title} </Link>
                                          </Typography>
                                          <Typography variant="body2" color="text.secondary">
                                             <Link href='/watch' className='views-time mt-2'>{formatViewsCount(video.view)} views </Link>
                                          </Typography>
                                       </div>
                                       <div className='col-1'>
                                          <div className="dropdown text-end">
                                             <a href="#" className="d-block caret-none  text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                   <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                                </svg>
                                             </a>
                                             <ul className="dropdown-menu">
                                                <li><Link className="dropdown-item" href="/login"><i className="me-3 bi bi-share"></i> Share</Link></li>
                                                <li><Link className="dropdown-item" href="/login"><i class="bi bi-send me-3"></i> Feedback</Link></li>

                                             </ul>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </CardContent>
                           </Card>
                        </div>
                     </div>
                  ))
               ) : (
                  <></>
               )}


            </div>
            </div>
         </div>

      </>
   )
}
export default Subscriptions
