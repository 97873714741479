import React, { useState } from 'react';
import Sidebar from '../../Components/Includes/Sidebar';
import { getToken } from "../../auth/AuthUser";
import Header from '../../Components/Includes/Header';
import SidebarIcon from '../../Components/Includes/SidebarIcon';
import { Link } from 'react-router-dom';
import { CheckCircle, CloseRounded } from '@mui/icons-material'; // Importing Material Icons

function SubscriptionPackage() {
    let token = getToken();

    // Sidebar state
    const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(280);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
        setSidebarCollapsed(!isSidebarCollapsed);
        setSidebarWidth(isSidebarCollapsed ? 280 : 80); // Toggle sidebar width
    };

    const subscriptionPlans = [
        {
            id: 1,
            name: 'Weekly Plan',
            price: '₹500',
            duration: '7 Days',
            videocount: '20',
            features: {
                createLongVideo: true,
                createShortVideo: true,
                ads: false,
                createChannel: true
            }
        },
        {
            id: 2,
            name: 'Monthly Plan',
            price: '₹750',
            duration: '30 Days',
            videocount: '30',
            features: {
                createLongVideo: true,
                createShortVideo: true,
                ads: true,
                createChannel: true
            }
        },
        {
            id: 3,
            name: 'Yearly Plan',
            price: '₹1000',
            duration: '365 Days',
            videocount: 'Unlimited',
            features: {
                createLongVideo: true,
                createShortVideo: true,
                ads: true,
                createChannel: true
            }
        }
    ];

    const [activePlanId, setActivePlanId] = useState(2); 

    return (
        <>
            <Header toggleSidebar={toggleSidebar} />
            <div className="col-xxl-2 col-xl-3 col-lg-3 display991" style={{ width: `${sidebarWidth}px` }}>
                {sidebarOpen ? (
                    <SidebarIcon onClick={toggleSidebar} />
                ) : (
                    <Sidebar onClick={toggleSidebar} />
                )}
            </div>
            <div className="col-xxl-9 col-xl-9 col-lg-9" style={{ width: isSidebarCollapsed ? '1266px' : 'calc(100% - 280px)' }}>
                <div className="main__body__wrap left__right__space pb-60 pt-20">
                    <div className='row g-3'>
                        <h3 className='mb-3'>Subscriptions Package</h3>
                        {subscriptionPlans.map(plan => (
                            <div key={plan.id} className='col-md-4 text-center'>
                                <div className="card h-100" style={{backgroundColor:'#cccccc2e'}}>
                                    <div className="card-body">
                                        <h2 className="card-title text-primary">{plan.name}</h2>
                                        <h1 className='mt-3' style={{color:'#f60'}}><strong>Price:</strong> {plan.price}</h1>
                                        <h5 className='mt-3'><strong>Duration:</strong> {plan.duration}</h5>
                                        <h5 className='mt-2 mb-5'><strong>Videos:</strong> {plan.videocount}</h5>
                                           <ul className="list-unstyled mt-3">
                                            <li className='mt-3'>
                                                {plan.features.createLongVideo ? 
                                                    <CheckCircle color="success" /> : <CloseRounded color="error" />} 
                                                <strong> Create Long Video</strong>
                                            </li>
                                            <li className='mt-3'>
                                                {plan.features.createShortVideo ? 
                                                    <CheckCircle color="success" /> : <CloseRounded color="error" />} 
                                                <strong> Create Short Video</strong>
                                            </li>
                                            <li className='mt-3'>
                                                {plan.features.ads ? 
                                                    <CheckCircle color="success" /> : <CloseRounded color="error" />} 
                                                <strong> Ads</strong>
                                            </li>
                                            <li className='mt-3'>
                                                {plan.features.createChannel ? 
                                                    <CheckCircle color="success" /> : <CloseRounded color="error" />} 
                                                <strong> Create Channel</strong>
                                            </li>
                                        </ul>
                                        {activePlanId === plan.id ? (
                                            <h4 className="text-success mt-5"><strong>Active Plan</strong></h4>
                                        ) : (
                                            <Link to={`/subscribe/${plan.id}`} className="btn btn-primary mt-5">
                                                Choose Plan
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default SubscriptionPackage;
