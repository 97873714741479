import { Route, Routes, Navigate } from "react-router-dom";

import Header from './Components/Includes/Header';
import Home from './Pages/Home';
import WatchSinglePage from './Pages/WatchSinglePage';
import Login from './Pages/Login';
import LikedVideos from './Pages/User/LikedVideos';
import HistoryVideos from './Pages/User/HistoryVideos';
import WatchLaterVideos from './Pages/User/WatchLaterVideos';
import Profile from './Pages/User/Profile';
import Channel from './Pages/User/Channel';
import MyChannel from './Pages/User/myChannel';
import Footer from './Components/Includes/Footer';
import VideoDetails from './Pages/VideoDetails';
import Signout from './Pages/User/Signout';
import ShortsVideo from './Pages/ShortsVideo';
import Subscriptions from './Pages/User/Subscriptions';
import Trending from './Pages/Trending';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import NotFound from './Components/ErrorPages/NotFound';
import SubscriptionChannels from './Pages/User/SubscriptionChannels';
import Handle from './Pages/Handle';
import Disclaimer from "./Pages/Disclaimer";
import About from "./Pages/About";
import ContactUs from "./Pages/ContactUs";
import Enquiry from "./Pages/Enquiry";


import { getToken } from "./auth/AuthUser";
import Support from "./Pages/Support";
import AccountDeleteReason from "./Pages/Delete";
import { useEffect } from "react";
import SubscriptionPackage from "./Pages/User/SubscriptionPackage";
import VideoCreater from "./Pages/VideoCreater";
import WithdrawList from "./Pages/User/WithdrawList";
import ChatBoard from "./Pages/User/ChatBoard";
import ThankYou from "./Pages/ThankYou";

function App() {

  const token = getToken();

  useEffect(() => {
    // Function to parse query parameters
    const getQueryParam = (paramName) => {
      const queryParams = new URLSearchParams(window.location.search);
      return queryParams.get(paramName);
    };

    // Get query parameter
    const myParamValue = getQueryParam('referId'); // Change 'myParam' to your desired query parameter name

    // Check if query parameter is available
    if (myParamValue !== null) {
      // Store in localStorage
      localStorage.setItem('referId', myParamValue);
    } else {
      console.log('Query parameter not available.');
    }
  }, []); // Empty array as second argument ensures useEffect runs only once


  return (
    <div className="left__site__section">
     {/* <Header />  */}


      <div className="popular__events__body">
        <div className="container-fluid p-0">
          <div className="row g-0">
            <Routes>
              {/* Base Routes */}
              <Route exact path="/" element={<Home />}></Route>
              <Route exact path="/login" element={<Login />}></Route>
              <Route exact path="/watch/:uniqId" element={<WatchSinglePage />} />
              <Route exact path="/shorts" element={<ShortsVideo />}></Route>
              <Route exact path="/shorts/:shortsId" element={<ShortsVideo />} />
              <Route path="/:channelHandle" element={<Handle />} />
              <Route exact path="/trending" element={<Trending />}></Route>

              {/* Private Routes */}
              <Route exact path="/profile" element={<Profile />}></Route>
              <Route exact path="/create-channel" element={token ? <MyChannel /> : <Navigate to="/login" />}></Route>
              <Route exact path="/subscription-plan" element={token ? <SubscriptionPackage /> : <Navigate to="/login" />}></Route>
              <Route exact path="/customization" element={token ? <MyChannel /> : <Navigate to="/login" />}></Route>
              <Route exact path="/channel" element={token ? <Channel /> : <Navigate to="/login" />}></Route>
              <Route exact path="/feed/subscriptions" element={token ? <Subscriptions /> : <Navigate to="/login" />}></Route>
              <Route exact path="/feed/channels" element={token ? <SubscriptionChannels /> : <Navigate to="/login" />}></Route>
              <Route exact path="/signout" element={token ? <Signout /> : <Navigate to="/" />}></Route>

              {/* In Developement Private Routes */}
              <Route exact path="/details" element={false ? <VideoDetails /> : <Navigate to="/" />}></Route>
              {/* <Route exact path="/liked-videos" element={false ? <LikedVideos /> : <Navigate to="/" />}></Route> */}
              <Route exact path="/liked-videos" element={<LikedVideos />}></Route>
              {/* <Route exact path="/history-videos" element={false ? <HistoryVideos /> : <Navigate to="/" />}></Route> */}
              <Route exact path="/history-videos" element={<HistoryVideos />}></Route>

              {/* <Route exact path="/watch-later-videos" element={false ? <WatchLaterVideos /> : <Navigate to="/" />}></Route> */}
              <Route exact path="/watch-later-videos" element={<WatchLaterVideos />}></Route>



              {/* Static Pages */}
              <Route exact path="/disclaimer" element={<Disclaimer />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/contact-us" element={<ContactUs />} />
              <Route exact path="/earnings-report" element={<VideoCreater />} />
              <Route exact path="/withdrawlist" element={<WithdrawList />} />
              <Route exact path="/app-chat" element={<ChatBoard />} />
              <Route exact path="/enquiry" element={<Enquiry />} />
              <Route exact path="/support" element={<Support />} />
              <Route exact path="/account-delete-reason" element={<AccountDeleteReason />} />
              <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route exact path="/thankyou" element={<ThankYou />} />



              {/* Error Pages and Redirections */}
              <Route exact path="/watch" element={<Navigate to="/" />} />
              <Route path="*" element={<NotFound />} />
            </Routes>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  );

}
export default App;
