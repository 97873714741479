import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const VideoCreatorGraph = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        margin={{
          top: 20, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="totalEarnings" fill="#ff6600" />
        {/* You can add more bars like below if needed */}
        {/* <Bar dataKey="views" fill="#2bb2e5" />
        <Bar dataKey="comments" fill="#ffc658" /> */}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default VideoCreatorGraph;
