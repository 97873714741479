import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Includes/Sidebar";
import Header from "../Components/Includes/Header";
import SidebarIcon from "../Components/Includes/SidebarIcon";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Enquiry() {
    // sidebar
    const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(280);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const [category, setCategory] = useState([]);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        companyName: '',
        adType: '',
        category: '',
        description: ''
    });

    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const validateForm = () => {
        const { name, email, mobile, companyName, adType, category, description } = formData;
        const newErrors = {};

        if (!name) newErrors.name = 'Name is required';
        if (!email) newErrors.email = 'Email is required';
        if (!mobile) newErrors.mobile = 'Mobile is required';
        if (!companyName) newErrors.companyName = 'Company Name is required';
        if (!adType) newErrors.adType = 'Ad Type is required';
        if (!category) newErrors.category = 'Category is required';
        if (!description) newErrors.description = 'Description is required';

        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
    
        if (Object.keys(validationErrors).length === 0) {
            setSubmitted(true);
            setErrors({});
    
            try {
                const response = await fetch('https://kbtube-backend.onrender.com/user/add-enquiry', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: new URLSearchParams(formData)
                });
    
                const result = await response.json();
                if (response.ok) {
                    toast.success('Inquiry submitted successfully!', {
                        autoClose: 5000,
                    });
                } else {
                    toast.error(`Error: ${result.message}`, {
                        autoClose: 5000,
                    });
                }
            } catch (error) {
                toast.error(`Error submitting the form: ${error.message}`, {
                    autoClose: 5000,
                });
            }
    
        } else {
            setErrors(validationErrors);
            toast.error('Please fill in all required fields correctly.', {
                autoClose: 5000,
                hideProgressBar: true
            });
        }
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
        setSidebarCollapsed(!isSidebarCollapsed);
        setSidebarWidth(isSidebarCollapsed ? 280 : 80); // Toggle sidebar width
    };

    // Fetch category data
    const getCateory = async () => {
        try {
            const response = await fetch('https://kbtube-backend.onrender.com/user/videoCategory', { method: 'POST' });
            const result = await response.json();
            if (response.ok) {
                setCategory(result.data || []);
            } else {
                console.error('Category not Found from the API:', result);
            }
        } catch (error) {
            console.error('Error fetching video categories:', error);
        }
    };

    useEffect(() => {
        getCateory();
    }, []);

    return (
        <>
            <Header toggleSidebar={toggleSidebar} />
            <div className="col-xxl-2 col-xl-3 col-lg-3 display991" style={{ width: `${sidebarWidth}px` }}>
                {sidebarOpen ? (
                    <SidebarIcon onClick={toggleSidebar} />
                ) : (
                    <Sidebar onClick={toggleSidebar} />
                )}
            </div>
            <div className="col-xxl-9 col-xl-9 col-lg-9" style={{ width: isSidebarCollapsed ? '1266px' : 'calc(100% - 280px)' }}>
                <div className="content-registration pt-3 bg-dark border">
                    <div className="container">
                        <div className="p-1">
                            <div className="p-1">
                                <div className='row g-3'>
                                    <div className="col-lg-12">
                                        <h2 className="mb-2 text-info">Enquiry Us</h2>
                                    </div>
                                    <hr />
                                    <div className="col-lg-12">
                                        {/* {submitted ? (
                                            <div className="alert alert-success">
                                                Thank you! Your inquiry has been submitted.
                                            </div>
                                        ) : (
                                            
                                        )} */}
                                        <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="name" className="form-label"><strong>Name</strong></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name"
                                                                name="name"
                                                                onChange={handleChange}
                                                            />
                                                            {errors.name && <div className="text-danger">{errors.name}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="email" className="form-label"><strong>Email</strong></label>
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                id="email"
                                                                name="email"
                                                                onChange={handleChange}
                                                            />
                                                            {errors.email && <div className="text-danger">{errors.email}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="mobile" className="form-label"><strong>Mobile</strong></label>
                                                            <input
                                                                type="tel"
                                                                className="form-control"
                                                                id="mobile"
                                                                name="mobile"
                                                                onChange={handleChange}
                                                            />
                                                            {errors.mobile && <div className="text-danger">{errors.mobile}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="companyName" className="form-label"><strong>Company Name</strong></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="companyName"
                                                                name="companyName"
                                                                onChange={handleChange}
                                                            />
                                                            {errors.companyName && <div className="text-danger">{errors.companyName}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="adType" className="form-label"><strong>Ad Type</strong></label>
                                                            <select className="form-control"
                                                                id="adType"
                                                                name="adType"
                                                                onChange={handleChange}>
                                                                <option value="">Select Type</option>
                                                                <option value="Skippable">Skippable</option>
                                                                <option value="Non-skippable">Non-skippable</option>
                                                            </select>
                                                            {errors.adType && <div className="text-danger">{errors.adType}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="category" className="form-label"><strong>Select Category</strong></label>
                                                            <select className="form-control"
                                                                id="category"
                                                                name="category"
                                                                onChange={handleChange}>
                                                                <option value="">Select Category</option>
                                                                {category.map((cat) => (
                                                                    <option key={cat.category} value={cat.category}>{cat.category}</option>
                                                                ))}
                                                            </select>
                                                            {errors.category && <div className="text-danger">{errors.category}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="description" className="form-label"><strong>Brief Description</strong></label>
                                                            <textarea
                                                                className="form-control"
                                                                id="description"
                                                                name="description"
                                                                placeholder="Brief Description...."
                                                                onChange={handleChange}
                                                            />
                                                            {errors.description && <div className="text-danger">{errors.description}</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <button type="submit" className="btn btn-success">Submit</button>
                                                </div>
                                            </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Toast Container should be added to make sure toasts are displayed */}
            <ToastContainer style={{zIndex:"99999999"}}/>
        </>
    );
}

export default Enquiry;
