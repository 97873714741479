import React, { useState, useEffect, useRef } from 'react';
import Sidebar from "../../Components/Includes/Sidebar";
import Header from "../../Components/Includes/Header";
import SidebarIcon from "../../Components/Includes/SidebarIcon";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { authHttps, getUser } from "../../auth/AuthUser";

function ChatBoard() {
    const http = authHttps();
    let userData = getUser();

    // Sidebar states
    const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(280);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');

    const receiverId = "67053ef0e9214c1860db0650";

    // Create a ref for chat messages container
    const messagesEndRef = useRef(null);

    // Toggle Sidebar
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
        setSidebarCollapsed(!isSidebarCollapsed);
        setSidebarWidth(isSidebarCollapsed ? 280 : 80);
    };

    useEffect(() => {
        fetchChatMessages();
    }, []);

    useEffect(() => {
        // Scroll to bottom whenever messages change
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const fetchChatMessages = async () => {
        setLoading(true);
        try {
            const response = await http.get(`user/${userData._id}/messages?otherUserId=${receiverId}`);
            const result = response.data;

            if (result.success && Array.isArray(result.data)) {
                setMessages(result.data.map((msg) => ({
                    id: msg._id,
                    sender: msg.sender === userData._id ? 'user' : 'admin',
                    text: msg.message,
                    read: msg.read,
                    sentAt: msg.sentAt
                })));
            } else {
                toast.error('Failed to fetch chat messages.');
            }
        } catch (error) {
            console.error('Error fetching chat messages:', error);
            toast.error('Error fetching chat messages.');
        } finally {
            setLoading(false);
        }
    };

    const handleSendMessage = async () => {
        if (newMessage.trim()) {
            try {
                const response = await http.post('user/send', {
                    senderId: userData._id,
                    receiverId: receiverId,
                    messageContent: newMessage
                });

                const result = response.data;

                if (result.success) {
                    setMessages((prevMessages) => [
                        ...prevMessages,
                        { id: result.data._id, sender: 'user', text: newMessage }
                    ]);
                    setNewMessage('');
                    toast.success('Message sent successfully!');
                } else {
                    toast.error('Failed to send message.');
                }
            } catch (error) {
                console.error('Error sending message:', error);
                toast.error('Error sending message.');
            }
        }
    };

    const handleRefreshMessage = async () => {
        fetchChatMessages();
    };

    return (
        <>
            <Header toggleSidebar={toggleSidebar} />
            <div className="col-xxl-2 col-xl-3 col-lg-3 display991" style={{ width: `${sidebarWidth}px` }}>
                {sidebarOpen ? (
                    <SidebarIcon onClick={toggleSidebar} />
                ) : (
                    <Sidebar onClick={toggleSidebar} />
                )}
            </div>
            <div className="col-xxl-9 col-xl-9 col-lg-9" style={{ width: isSidebarCollapsed ? '1266px' : 'calc(100% - 280px)' }}>
                <div className="content-registration pt-3 bg-dark">
                    <div className="container">
                        <div className="p-1">
                            <div className="p-1">
                                <div className='row g-3'>
                                    <div className="col-lg-12">
                                        <div className=''>
                                            <div className='d-flex'>
                                                <h4 className="mb-2">Chat With Admin</h4>
                                                {/* <button style={{ marginLeft: 'auto' }} onClick={handleRefreshMessage} className='btn btn-info text-white'>Refresh</button> */}
                                            </div>
                                            <hr />
                                            <div className="chat-board">
                                                <div className="chat-messages" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                    {messages.map((message) => (
                                                        <div
                                                            key={message.id}
                                                            className={`message ${message.sender === 'user' ? 'user-message' : 'admin-message'}`}
                                                        >
                                                            <div className={`message-bubble ${message.sender === 'user' ? 'user-bubble' : 'admin-bubble'}`}>
                                                                {message.text}
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {/* Reference for scrolling to the bottom */}
                                                    <div ref={messagesEndRef} />
                                                </div>

                                                <div className="chat-input-container">
                                                    <input
                                                        type="text"
                                                        placeholder="Type your message..."
                                                        value={newMessage}
                                                        onChange={(e) => setNewMessage(e.target.value)}
                                                        className="chat-input"
                                                        required
                                                    />
                                                    <button onClick={handleSendMessage} className="send-button btn btn-info">
                                                        Send
                                                    </button>
                                                    <button  onClick={handleRefreshMessage} className='btn btn-info text-white ms-2'>Refresh</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer style={{ zIndex: '999999' }} />
        </>
    );
}

export default ChatBoard;
