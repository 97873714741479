import React, { useState, useEffect } from 'react';
import Sidebar from "../Components/Includes/Sidebar";
import Header from '../Components/Includes/Header';
import SidebarIcon from '../Components/Includes/SidebarIcon';
function PrivacyPolicy() {

    // sidebar
   const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
   const [sidebarWidth, setSidebarWidth] = useState(280);
   const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
        setSidebarCollapsed(!isSidebarCollapsed);
        setSidebarWidth(isSidebarCollapsed ? 280 : 80); // Toggle sidebar width
    };
    return (
        <>
             <Header toggleSidebar={toggleSidebar} />
            <div className="col-xxl-2 col-xl-3 col-lg-3 display991" style={{ width: `${sidebarWidth}px` }}>
                {sidebarOpen ? (
                    <SidebarIcon onClick={toggleSidebar} />
                ) : (
                    <Sidebar onClick={toggleSidebar} />
                )}
            </div>
            <div className="col-xxl-9 col-xl-9 col-lg-9" style={{ width: isSidebarCollapsed ? '1266px' : 'calc(100% - 280px)' }}>
                <div className="content-registration pt-3 bg-dark ">
                    <div className="container">
                        <div className="p-1">
                            <div className="p-1">
                                <div className='row g-3' >
                                    <div className="col-lg-12">
                                        <header>
                                            <h1>Privacy Policy</h1>
                                        </header>
                                        <section id="contact-info">
                                            <h4 className='mb-2 mt-3'>Contact Info</h4>
                                            <ul>
                                                <li>Name: KB TuBE</li>
                                                <li>Email: <a href="mailto:contact@kbtube.com">contact@kbtube.com</a></li>
                                                <li>Phone Number: <a href="tel:+13053171806">+1 305-317-1806</a></li>
                                                <li>Address: KB TuBE Headquarters, 4300 Biscayne Blvd Suite 203, Miami, Florida 33137, US</li>
                                            </ul>
                                        </section>
                                        <section id="payment-info">
                                            <h4 className='mb-2 mt-2'>Payment Info</h4>
                                            <p>No payment information is stored within this application.</p>
                                        </section>
                                        <section id="location">
                                            <h4 className='mb-2 mt-2'>Location</h4>
                                            <p>We do not collect precise location data.</p>
                                        </section>
                                        <section id="sensitive-info">
                                            <h4 className='mb-2 mt-2'>Sensitive Info</h4>
                                            <p>We do not collect sensitive personal information.</p>
                                        </section>
                                        <section id="contacts">
                                            <h4 className='mb-2 mt-2'>Contacts</h4>
                                            <p>We do not access or store your contact list or address book.</p>
                                        </section>
                                        <section id="user-content">
                                            <h4 className='mb-2 mt-2'>User Content</h4>
                                            <p>We may collect emails or text messages exchanged within the app for customer support purposes only.</p>
                                        </section>
                                        <section id="browsing-history">
                                            <h4 className='mb-2 mt-2'>Browsing History</h4>
                                            <p>We do not track your browsing history outside of the app.</p>
                                        </section>
                                        <section id="search-history">
                                            <h4 className='mb-2 mt-2'>Search History</h4>
                                            <p>We may collect information about searches performed within the app for improving user experience.</p>
                                        </section>
                                        <section id="identifiers">
                                            <h4 className='mb-2 mt-2'>Identifiers</h4>
                                            <p>We may collect user IDs for authentication and account management purposes.</p>
                                        </section>
                                        <section id="device-id">
                                            <h4 className='mb-2 mt-2'>Device ID</h4>
                                            <p>We may collect device identifiers for technical diagnostics and app functionality.</p>
                                        </section>
                                        <section id="purchase-history">
                                            <h4 className='mb-2 mt-2'>Purchase History</h4>
                                            <p>No purchase history is collected within this application.</p>
                                        </section>
                                        <section id="usage-data">
                                            <h4 className='mb-2 mt-2'>Usage Data</h4>
                                            <p>We collect data on app interactions and usage patterns to improve our services.</p>
                                        </section>
                                        <section id="diagnostics">
                                            <h4 className='mb-2 mt-2'>Diagnostics</h4>
                                            <p>We collect crash and performance data for diagnosing and improving app performance.</p>
                                        </section>
                                        <section id="data-use">
                                            <h4 className='mb-2 mt-2'>Data Use</h4>
                                            <p>We use collected data for app functionality, analytics, and product personalization purposes only.</p>
                                        </section>
                                        <section id="data-linked">
                                            <h4 className='mb-2 mt-2'>Data Linked to You</h4>
                                            <p>We only collect data linked to your identity for account management and authentication.</p>
                                        </section>
                                        <section id="data-not-linked">
                                            <h4 className='mb-2 mt-2'>Data Not Linked to You</h4>
                                            <p>We may collect anonymized data for analytics and product improvement purposes.</p>
                                        </section>
                                        <section id="data-used-to-track">
                                            <h4 className='mb-2 mt-2'>Data Used to Track You</h4>
                                            <p>We do not engage in tracking activities that identify individual users for targeted advertising purposes.</p>
                                        </section>
                                        <section id="third-party">
                                            <h4 className='mb-2 mt-2'>Third-Party Data</h4>
                                            <p>We do not collect third-party data from apps, websites, or offline properties.</p>
                                        </section>
                                        <footer>
                                            <p>Last updated: 2024-03-15</p>
                                        </footer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default PrivacyPolicy