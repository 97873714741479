import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../Components/Includes/Sidebar'
import { useNavigate, useParams } from 'react-router-dom';
import profilePic from "../assets/images/profile-pic.jpg";
import ImgCreateVideo from '../assets/images/channel-create.png';
import { authHttps, getToken } from "../auth/AuthUser";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { formatDistanceToNow } from 'date-fns';
import channelbanner from "../assets/images/channels4_banner.jpg";


import VideoListLoading from '../Components/LoadingPages/VideoListLoading';
import { ShortsRoute, VideoRoute } from '../utils/routeUtils';
import VideosNotFound from '../Components/ErrorPages/VideosNotFound';
import ShortsListingLoading from '../Components/LoadingPages/ShortsListingLoading';
import ShimmerLoading from '../Components/LoadingPages/ShimmerLoading';
import handleSanitize from '../utils/handleSanitize';
import VideoNotAvailable from '../Components/ErrorPages/VideoNotAvailable';
import NotFound from '../Components/ErrorPages/NotFound';
import Header from '../Components/Includes/Header';
import SidebarIcon from '../Components/Includes/SidebarIcon';
import { Facebook } from '@mui/icons-material';

const TimeAgo = ({ date }) => {
	// Assuming `date` is a string in the format 'YYYY-MM-DDTHH:mm:ss.sssZ'
	const parsedDate = new Date(date);

	const timeAgo = formatDistanceToNow(parsedDate, { addSuffix: true });

	return <span>{timeAgo}</span>;
};

const formatViewsCount = (count) => {
	if (count < 1000) {
		return count.toString();
	} else if (count < 1000000) {
		return `${(count / 1000).toFixed(1)}K`;
	} else {
		return `${(count / 1000000).toFixed(1)}M`;
	}
};

function Handle() {


	const { channelHandle } = useParams();
	const http = authHttps();

	const [loadingChannel, setLoadingChannel] = useState(true);
	const [channelData, setChannelData] = useState([]);
	const [allVideo, setallVideo] = useState([]);
	const [shortVideo, setshortVideo] = useState([]);
	const [loadingvideos, setloadingvideos] = useState(true);
	const [loadingvideos2, setloadingvideos2] = useState(true);
	const [name, setName] = useState(null);
	const [handel, setHandel] = useState(null);
	const [description, setDescription] = useState(null);
	const [contact, setContact] = useState('');
	const [photo, setPhoto] = useState('');
	const [banner, setBanner] = useState('');
	const [subscriber, setsubscriber] = useState(0);

	//  new staet for modal data
	const [facebookLink, setFacebookLink] = useState(null);
	const [twitterLink, setTwitterLink] = useState(null);
	const [instagramLink, setInstagramLink] = useState(null);
	const [contactLink, setContactLink] = useState(null);

	// sidebar
	const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
	const [sidebarWidth, setSidebarWidth] = useState(280);
	const [sidebarOpen, setSidebarOpen] = useState(false);

	const navigate = useNavigate();

	const viewVideo = async (videoId) => {
		await http.post('user/addView', { 'videoId': videoId });
	};

	const fetchVideo = async (handel) => {
		try {
			const response = await http.post('user/handelWiseVideo', { type: 1, handel: handel });
			if (response.data && response.data.data) {
				//   console.log('allvideo', response.data.data);
				setallVideo(response.data.data);
			} else {
				console.error('Invalid data structure received from the API:', response.data);
			}
		} catch (error) {
			console.error('Error fetching video categories:', error);
		} finally {
			setloadingvideos(false);
		}
	};


	const fetchVideo2 = async (handel) => {
		try {
			const response = await http.post('user/handelWiseVideo', { type: 2, handel: handel });
			if (response.data && response.data.data) {
				//   console.log('allvideo', response.data.data);
				setshortVideo(response.data.data);
			} else {
				console.error('Invalid data structure received from the API:', response.data);
			}
		} catch (error) {
			console.error('Error fetching video categories:', error);
		} finally {
			setloadingvideos2(false);
		}
	};


	const fetchChannel = async (channelHandle) => {
		const handel = handleSanitize(channelHandle);
		setLoadingChannel(true);
		try {
			const response = await http.post('user/handelWiseChannelData', { "handel": handel });
			// console.log(response);
			// if (response.data && response.data.status == 0) {

			// }
			if (response.data && response.data.status == 1) {

				setChannelData(response.data.data);

				setName(response.data.data.name);
				setHandel(response.data.data.url);
				setDescription(response.data.data.description);
				setContact(response.data.data.contact);
				setPhoto(response.data.data.photo);
				setBanner(response.data.data.banner);
				setsubscriber(response.data.data.subscriber);
				setFacebookLink(response.data.data.facebookLink);
				setTwitterLink(response.data.data.twitterLink);
				setInstagramLink(response.data.data.instagramLink);
				setContactLink(response.data.data.setContactLink);


				fetchVideo(handel);
				fetchVideo2(handel);

			}
		} catch (error) {
			console.error('Error fetching Channel:', error);
		} finally {
			setLoadingChannel(false);
		}
	};

	useEffect(() => {
		fetchChannel(channelHandle);
	}, [channelHandle]);

	const toggleSidebar = () => {
		setSidebarOpen(!sidebarOpen);
		setSidebarCollapsed(!isSidebarCollapsed);
		setSidebarWidth(isSidebarCollapsed ? 280 : 80); // Toggle sidebar width
	};

	return (
		<>
			{loadingChannel || channelData && channelData.name ? (
				<>

					<Header toggleSidebar={toggleSidebar} />
					<div className="col-xxl-2 col-xl-3 col-lg-3 display991" style={{ width: `${sidebarWidth}px` }}>
						{sidebarOpen ? (
							<SidebarIcon onClick={toggleSidebar} />
						) : (
							<Sidebar onClick={toggleSidebar} />
						)}
					</div>
					<div className="col-xxl-9 col-xl-9 col-lg-9" style={{ width: isSidebarCollapsed ? '1266px' : 'calc(100% - 280px)' }}>

						<div className="main__body__wrap left__right__space pb-60">
							<div className='container'>
								<div className='row'>
									<div className='col-lg-12'>
										<div className='profile-box video-box-single-h '>
											<div className='my-3 mb-4' style={{ height: '180px', overflow: 'hidden' }}>
												{
													banner ? (
														<img src={banner} alt='Banner' width="100%" style={{ borderRadius: '10px', overflow: 'hidden' }} />
													) : (
														<img src={channelbanner} alt='Banner' width="100%" style={{ borderRadius: '10px', overflow: 'hidden' }} />
													)}
											</div>
											<div className='row g-3 align-items-center'>
												{/* banner channel */}
												<div className='col-md-6 col-lg-3 col-xl-2'>
													<div className='profile-pic-box rounded-circle bg-white '>
														{
															photo ? (
																<img src={photo} className="rounded-circle  img-fluid" />
															) : (
																<img src={profilePic} className="rounded-circle  img-fluid" />
															)}
													</div>
												</div>
												<div className='col-lg-10 px-lg-3'>
													<div className='content-box shimmer-loading-list mt-0'>
														{
															name ?
																<Link to='/channel' className='text-dark'>
																	<h3>{name}</h3>
																</Link>
																:
																<ShimmerLoading width="30%" height="20px" />
														}


														{(handel && allVideo) ?
															<>
																<div className='channel-name'>
																	<Link to='/channel' className='text-theme'> @{handel} </Link>
																	<span> <i className='bi bi-dot'></i> </span>
																	<span className='text-secondary'>{subscriber} Subscriber</span>
																	<span> <i className='bi bi-dot'></i> </span>
																	<span className='text-secondary'>{allVideo && allVideo.length} videos</span>
																	<p className='mt-1 mb-0'>
																		<Link href='/channel' className='text-secondary'>
																			{description}
																		</Link>
																		<span style={{ cursor: 'pointer' }} data-bs-toggle="modal"
																			data-bs-target="#aboutModal"><b>..more</b>
																		</span>
																	</p>
																	<p className='mb-0'><Link>{twitterLink || 'twitter.com'}</Link>
																		<span style={{ cursor: 'pointer' }}
																			data-bs-toggle="modal"
																			data-bs-target="#aboutModal">
																			<b>  and 4 more links</b>
																		</span>
																	</p>
																	<button className='btn btn-secondary rounded-5'>Subscribe</button>
																</div>

															</>

															:
															<>
																<ShimmerLoading width="60%" height="20px" />
																<ShimmerLoading width="90%" height="20px" />
																<ShimmerLoading width="20%" borderRadius="40px" height="30px" />
															</>
														}

													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* top channer profile  row end */}
								<div className='border-top my-3'></div>
								<div className='row'>
									{/* tabs st start  */}
									<ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
										<li className="nav-item" role="presentation">
											<button
												className="nav-link active py-2 px-3"
												id="pills-video-tab"
												data-bs-toggle="pill"
												data-bs-target="#pills-video"
												type="button"
												role="tab"
												aria-controls="pills-video"
												aria-selected="true"
											>
												Videos
											</button>
										</li>
										<li className="nav-item" role="presentation">
											<button
												className="nav-link py-2 px-3"
												id="pills-shorts-tab"
												data-bs-toggle="pill"
												data-bs-target="#pills-shorts"
												type="button"
												role="tab"
												aria-controls="pills-shorts"
												aria-selected="false"
											>
												Shorts
											</button>
										</li>
									</ul>
									<hr />
									<div className="tab-content" id="pills-tabContent">
										<div
											className="tab-pane fade show active"
											id="pills-video"
											role="tabpanel"
											aria-labelledby="pills-video-tab"
										>
											<div className='row g-3 row-cols-lg-3 row-cols-xl-3 row-cols-md-2 row-cols-sm-2'>
												{loadingvideos ? (
													<VideoListLoading />
												) : allVideo.length > 0 ? (
													allVideo.map((video, index) => (
														<div className='col'>
															<div className='video-box-single-v with-action' onClick={() => viewVideo(video.videoId)}>

																<Card className='shadow-none h-194-video'>

																	<div className='video-box cursor-pointer'>
																		<Link to={VideoRoute(video.uniqId)}>
																			{/*--<span className='duration-time'>11:11:11</span>--*/}
																			<div>
																				<CardMedia
																					component="video"
																					alt="Video Poster"
																					poster={video.thumbnail}
																					className='w-100'
																					muted
																				>
																					<source src={video.url} className='w-100' type="video/mp4" />
																				</CardMedia>
																			</div>

																		</Link>
																	</div>
																	<CardContent>
																		<div className='content-box'>
																			<div className='row align-items-start g-0'>
																				<div class="col-2">
																					<Link to={'/@' + video.handel}>
																						<div className='channel-thumbnail'>

																							{
																								video.channelIcon ? (
																									<img src={video.channelIcon} className="rounded-circle  img-fluid" />
																								) : (
																									<img src={profilePic} className="rounded-circle  img-fluid" />
																								)}
																						</div>
																					</Link>
																				</div>
																				<div className='col-9'>
																					<Typography variant="h5" component="div" className='v-tiltle'>
																						{/* <Link href='/watch'>{video.title}</Link> */}

																						<Link to={VideoRoute(video.uniqId)}> {video.title}</Link>
																					</Typography>
																					<Typography variant="body2" color="text.secondary">
																						<Link to={'/@' + video.handel} className='channel-name mt-2'>{video.channelName}</Link>
																						<Link to={VideoRoute(video.uniqId)} className='views-time'>{formatViewsCount(video.view)} views <span>.</span> {<TimeAgo date={video.createdAt} />}</Link>
																					</Typography>
																				</div>
																				<div className='col-1'>
																					<div className="dropdown text-end">
																						<a href="#" className="d-block caret-none  text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
																							<i class="bi bi-three-dots-vertical"></i>
																						</a>
																						<ul className="dropdown-menu">
																							<li><Link className="dropdown-item" href="/login"><i class="bi bi-bar-chart-steps me-3"></i>Add to queue</Link></li>
																							<li><Link className="dropdown-item" href="/login"><i className="me-3 bi bi-clock-history"></i> Save to Watch Later</Link></li>
																							<li><Link className="dropdown-item" href="/login"><i class="bi bi-plus-square me-3"></i> Save to Playlist</Link></li>
																							<li><Link className="dropdown-item" href="/login"><i class="bi bi-download me-3"></i> Download</Link></li>
																							<li><Link className="dropdown-item" href="/login"><i className="me-3 bi bi-share"></i> Share</Link></li>
																							<li><hr className="dropdown-divider" /></li>
																							<li><Link className="dropdown-item" href="#"><i className="me-3 bi bi-ban"></i>  Not Interested </Link></li>
																							<li><Link className="dropdown-item" href="#"><i class="bi bi-dash-circle me-3"></i>  Don't Recomended Channel </Link></li>
																							<li><Link className="dropdown-item" href="#"><i className="me-3 bi bi-flag"></i>  Report </Link></li>
																						</ul>
																					</div>
																				</div>
																			</div>
																		</div>
																	</CardContent>
																</Card>
															</div>
														</div>
													))
												) : (
													<VideosNotFound />
												)}


											</div>
										</div>
										<div
											className="tab-pane fade"
											id="pills-shorts"
											role="tabpanel"
											aria-labelledby="pills-shorts-tab"
										>
											<div className='row row-cols-lg-5 row-cols-md-3 row-cols-sm-2'>
												{loadingvideos2 ? (
													<ShortsListingLoading />
												) : shortVideo.length > 0 ? (
													shortVideo.map((video, index) => (
														<div className='col'>
															<div className='video-box-single-v with-action' onClick={() => viewVideo(video.videoId)}>
																<Card className='shorts-card shadow-none'>
																	<div className='video-box cursor-pointer'>

																		<Link to={ShortsRoute(video.uniqId)}>
																			{/*--<span className='duration-time'>11:11:11</span>--*/}
																			<div>
																				<CardMedia
																					component="video"
																					alt="Video Poster"
																					poster={video.thumbnail}
																					className='w-100'
																					muted
																				// controls
																				>
																					<source src={video.url} className='w-100' type="video/mp4" />
																				</CardMedia>
																			</div>
																		</Link>
																	</div>
																	<CardContent>
																		<div className='content-box'>
																			<div className='row align-items-start g-0'>

																				<div className='col-11'>
																					<Typography variant="h5" component="div" className='v-tiltle'>
																						{/* <Link href='/watch'>{video.title}</Link> */}

																						<Link to={ShortsRoute(video.uniqId)}>{video.title} </Link>
																					</Typography>
																					<Typography variant="body2" color="text.secondary">
																						<Link href='/watch' className='views-time mt-2'>{formatViewsCount(video.view)} views </Link>
																					</Typography>
																				</div>
																				<div className='col-1'>
																					<div className="dropdown text-end">
																						<a href="#" className="d-block caret-none  text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
																							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
																								<path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
																							</svg>
																						</a>
																						<ul className="dropdown-menu">
																							<li><Link className="dropdown-item" href="/login"><i className="me-3 bi bi-share"></i> Share</Link></li>
																							<li><Link className="dropdown-item" href="/login"><i className="bi bi-send me-3"></i> Feedback</Link></li>

																						</ul>
																					</div>
																				</div>
																			</div>
																		</div>
																	</CardContent>
																</Card>
															</div>
														</div>
													))
												) : (
													<></>
												)}

											</div>
										</div>
									</div>

									{/* tabs st close */}
								</div>
							</div>
						</div>
					</div>

					{/* model for about channel */}
					<div
						className="modal fade"
						id="aboutModal"
						tabIndex={-1}
						aria-labelledby="aboutModalLabel"
						aria-hidden="true"
					>
						<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
							<div className="modal-content">
								<div className="modal-header">
									<h4 className="modal-title" id="aboutModalLabel">About</h4>
									<button
										type="button"
										className="btn-close"
										data-bs-dismiss="modal"
										aria-label="Close"
									/>
								</div>
								<div className="modal-body">
									<p style={{ fontSize: '14px' }}>
										{description || 'We provide tutorials for SolidWorks, Autodesk Inventor, and Fusion 360. Contact us for more information!'}
									</p>
									<div className=''>
										<h4 className='mb-3'>Links</h4>
										<ul>
											<li className='d-flex mb-2'>
												<i className="bi bi-twitter-x me-3"></i>
												<h6>
													<a href={twitterLink} className='fs-12 text-info'>
														{twitterLink || 'Add Twitter Link'}
													</a>
												</h6>
											</li>
											<li className='d-flex mb-2'>
												<i className="bi bi-facebook me-3"></i>
												<h6>
													<a href={facebookLink} className='fs-12 text-info'>
														{facebookLink || 'Add Facebook Link'}
													</a>
												</h6>
											</li>
											<li className='d-flex mb-2'>
												<i className="bi bi-instagram me-3"></i>
												<h6>
													<a href={instagramLink} className='fs-12 text-info'>
														{instagramLink || 'Add Instagram Link'}
													</a>
												</h6>
											</li>
										</ul>
									</div>

									<div className=''>
										<h4 className='mb-3'>Channel Details</h4>
										<ul>
											<li className='d-flex mb-2'>
												<i className="bi bi-envelope me-3"></i>
												<h6>{contact}</h6>
											</li>
											<li className='d-flex mb-2'>
												<i className="bi bi-people me-3"></i>
												<h6>{subscriber} Subscribers</h6>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* modal about close  */}

				</>
			) : (
				<NotFound />
			)}
		</>
	)
}

export default Handle