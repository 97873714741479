import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { getToken } from "../../auth/AuthUser";
import profile from '../../assets/images/profile-pic.jpg';
import { authHttps } from "../../auth/AuthUser";
import { Switch, FormControlLabel } from '@mui/material';
import { Brightness4, Brightness7, SupervisedUserCircle, VideoCall } from '@mui/icons-material'; // Import the moon and sun icons

function Topbar() {
    const http = authHttps();
    let token = getToken();
    const [chanenl, setChanneel] = useState(false);
    const [profiledata, setProfile] = useState([]);

    const [paymentList, setPaymentList] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState('pending');
    const [notificationList, setNotificationList] = useState([]);
    // const [notificationId, setNotificationId] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    }


    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark');

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme) {
            document.body.className = theme;
            setTheme(savedTheme);
        } else {

            localStorage.setItem('theme', theme);
        }
    }, []);


    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        localStorage.setItem('theme', newTheme);
        if (theme === 'light') {
            document.body.classList.add('light');
            document.body.classList.remove('dark');
        } else {
            document.body.classList.add('dark');
            document.body.classList.remove('light');
        }
        document.body.style.setProperty('--body-color', newTheme === 'light' ? 'hsl(0, 0%, 98%)' : '#060C1F');
        document.body.style.setProperty('--header', newTheme === 'light' ? '#eaeaea' : '#202A39');
        document.body.style.setProperty('--subheader', newTheme === 'light' ? '#dadada' : '#161F2C');
        document.body.style.setProperty('--bg-theme-1', newTheme === 'light' ? 'var(--active-3)' : 'var(--active-color)');
        document.body.style.setProperty('--theme-one', newTheme === 'light' ? 'var(--theme-two)' : '#88B6FF');
        document.body.style.setProperty('--slidebox', newTheme === 'light' ? '#ebebeb' : '#141C33');
        document.body.style.setProperty('--white', newTheme === 'light' ? '#000' : '#fff');
        document.body.style.setProperty('--black', newTheme === 'light' ? '#fff' : '#000');
        document.body.style.setProperty('--white-color', newTheme === 'light' ? '#000' : '#fff');
        document.body.style.setProperty('--black-color', newTheme === 'light' ? '#000' : '#000');
        document.body.style.setProperty('--bs-modal-color', newTheme === 'light' ? '#000' : '#fff');
        document.body.style.setProperty('--border-theme2', newTheme === 'light' ? '#797979' : '#797979');
        document.body.style.setProperty('--border-theme3', newTheme === 'light' ? '#555' : '#555');
        document.body.style.setProperty('--border-theme4', newTheme === 'light' ? '#333' : '#333');
        document.body.style.setProperty('--color-444', newTheme === 'light' ? '#444' : '#444');
        document.body.style.setProperty('--bg-secondary', newTheme === 'light' ? 'var(--bg-theme-1)' : 'var(--bg-theme-1)');
        document.body.style.setProperty('--color-text', newTheme === 'light' ? '#333' : '#a9a9a9');
        document.body.style.setProperty('--bs-light-rgb', newTheme === 'light' ? '0,0,0' : '248,249,250');
        document.body.style.setProperty('--bs-white-rgb', newTheme === 'light' ? '0,0,0' : '255,255,255');
        document.body.style.setProperty('--bs-nav-link-color', newTheme === 'light' ? '#000' : 'var(--icon-color)');
        setTheme(newTheme);
    };

    const fetchChannel = async () => {
        try {
            const response = await http.post('user/myChanel');
            if (response.data && response.data.status == 1) {

                setChanneel(true);

            }
        } catch (error) {
            console.error('Error fetching Channel:', error);
        }
    };


    useEffect(() => {
        fetchChannel();
    }, []);


    const fetchUser = async () => {
        try {
            const response = await http.post('user/getUser');
            // console.log('profile', response.data.data);
            if (response.data && response.data.status == 1) {

                setProfile(response.data.data);



            }
        } catch (error) {
            setProfile([]);
        }
    };


    useEffect(() => {
        fetchUser();
    }, []);

    const [fl, setFirstLetter] = useState(null);


    useEffect(() => {
        const letters = profiledata.full_name ? profiledata.full_name.split('') : [];
        const firstLetter = letters.length > 0 ? letters[0] : '';

        setFirstLetter(firstLetter);

    }, [profiledata]);


    // payment list api new function
    const fetchPaymentList = async () => {
        try {
            const response = await http.get('user/payment_list');
            if (response.data) {
                setPaymentList(response.data.data);
                setPaymentStatus(response.data.data[0].status);
            } else {
                console.error('Invalid data structure received from the API:', response.data);
            }
        } catch (error) {
            console.error('Error fetching video categories:', error);
        }
    };


    const fetchNotificationList = async () => {
        try {
            const response = await http.get('user/notifications');
            if (response.data) {
                setNotificationList(response.data.data);
            } else {
                console.error('Invalid data structure received from the API:', response.data);
            }
        } catch (error) {
            console.error('Error fetching video categories:', error);
        }
    };

    const markAsRead = async (notificationId) => {
        try {
            const response = await http.get(`user/notifications/${notificationId}/read`);
            if (response.data) {
                fetchNotificationList();  // Fetch updated list after marking notification as read
            } else {
                console.error('Invalid data structure received from the API:', response.data);
            }
        } catch (error) {
            console.error('Error fetching notification:', error);
        }
    };

    useEffect(() => {
        fetchPaymentList();
        fetchNotificationList();
    }, []);

    return (

        <>
            <div className='col-lg-2 col-8 order-lg-2 d-flex align-items-center justify-content-end'>
                {/*<div className='d-flex align-items-center justify-content-end'>
                    <FormControlLabel
                        control={<Switch checked={theme === 'dark'} onChange={toggleTheme} />}
                        label={theme === 'dark' ? <i className="bi secondary-color"><Brightness4 /></i> : <i className="bi secondary-color"><Brightness7 /></i>} // Render moon or sun icon based on theme
                    />
    </div>*/}
                {
                    token ?
                        <div className='d-flex align-items-center justify-content-end'>
                            <div className="me-lg-4 me-2">
                                {/* Plus icon button */}
                                {paymentList.length > 0 ? (
                                    paymentStatus !== 'approved' ? (
                                        <a href="#" className="navbtn-ico caret-none" title='Create' style={{ pointerEvents: 'none', opacity: '0.5' }}>
                                            <i className="bi bi-plus-circle fs-4 fw-700"></i>
                                        </a>
                                    ) : (
                                        <a href="#" className="navbtn-ico caret-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" title='Create'>
                                            <i className="bi bi-plus-circle fs-4 fw-700"></i>
                                        </a>
                                    )
                                ) : (
                                    <a
                                        href="#"
                                        className="navbtn-ico caret-none"
                                        title='Create'
                                        data-bs-toggle={chanenl ? "modal" : ""}
                                        data-bs-target={chanenl ? "#paymentModal" : ""}
                                        onClick={!chanenl ? () => window.location.href = './create-channel' : null}
                                    >
                                        <i className="bi bi-plus-circle fs-4 fw-700"></i>
                                    </a>
                                )}

                                {/* Dropdown for uploading video or creating a channel */}
                                <ul className="dropdown-menu">
                                    {chanenl ? (
                                        <li>
                                            <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#videoUpload">Upload Video</a>
                                        </li>
                                    ) : (
                                        <li>
                                            <Link to="./create-channel" className="dropdown-item">Create Channel</Link>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <div className="dropdown text-end me-4">
                                <button
                                    className="d-block caret-none text-decoration-none"
                                    onClick={toggleDropdown}
                                    style={{ background: 'none', border: 'none', outline: 'none' }}
                                >
                                    <i className="bi bi-bell secondary-color fs-4"></i>
                                </button>

                                {/* Dropdown menu */}
                                {isDropdownOpen && (
                                    <div className="dropdown-menu p-3 show" style={{ display: 'block', position: 'absolute', right: '0' }}>
                                        <h4>Notification</h4>
                                        <hr />
                                        {notificationList && notificationList.length > 0 ? (
                                            notificationList.map((notification) => (
                                                <li
                                                    key={notification._id}
                                                    onClick={() => markAsRead(notification._id)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <div className="mb-2" style={{ minWidth: '320px' }}>
                                                        <div className="row border-bottom">
                                                            <div className="col-2 text-center">
                                                                {notification.isRead === true ? (
                                                                    <i className="bi bi-check-all" style={{ fontSize: '35px', color: '#ff6600' }}></i>
                                                                ) : (
                                                                    <i className="bi bi-check" style={{ fontSize: '35px', color: '#1eafe4' }}></i>
                                                                )}
                                                            </div>
                                                            <div className="col-10">
                                                                <p className="mb-0 fs-6 mt-0">{notification.notification}</p>
                                                                <small className="me-2">
                                                                    <span>Date: </span>
                                                                    {new Date(notification.createdAt).toLocaleDateString()}
                                                                </small>
                                                                <small>
                                                                    <span>Time: </span>
                                                                    {new Date(notification.createdAt).toLocaleTimeString()}
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))
                                        ) : (
                                            <li>No Notifications</li>
                                        )}
                                    </div>
                                )}
                            </div>

                            <div className="dropdown text-end">
                                <a href="#" className="d-block caret-none  text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">

                                    {profiledata.photo && profiledata.photo !== "N/A" ? (
                                        <img src={profiledata.photo} alt="mdo" width="45" height="45" className="rounded-circle" />
                                    ) : (

                                        <div
                                            className='profile-ico'
                                        >
                                            {fl ? fl : ''}

                                        </div>



                                    )}
                                </a>
                                <ul className="dropdown-menu ">

                                    <li><Link className="dropdown-item" to="/profile">Profile</Link></li>
                                    <li><Link className="dropdown-item" to="/support">Support</Link></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <div className="form-check form-switch ms-3 mt-2 mb-2" >
                                        <input
                                            style={{cursor:'pointer'}}
                                            className="form-check-input"
                                            type="checkbox"
                                            id="flexSwitchCheckDefault"
                                        />
                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                            <b>Kids Mode </b>
                                        </label>
                                    </div>

                                    <li><hr className="dropdown-divider" /></li>
                                    <Link to="/signout"><a className="dropdown-item" href="#">Sign out</a></Link>
                                </ul>
                            </div>
                        </div>
                        :

                        <div className="mneu-btn-grp">

                            <Link
                                className="cmn--btn"
                                style={{ padding: '10px 30px' }}
                                href="URL:void(0)"
                                data-bs-toggle="modal"
                                data-bs-target="#signInPin"
                            >
                                <span>Login</span>
                            </Link>
                            {/* <Link
                                href="URL:void(0)"
                                className="cmn--btn2"
                                data-bs-toggle="modal"
                                data-bs-target="#signUpPin"
                            >
                                <span className="rela">Sign Up</span>
                            </Link> */}
                        </div>
                }
            </div>
        </>
    )
}

export default Topbar