import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../Components/Includes/Sidebar';
import profilePic from "../assets/images/profile-pic.jpg";
import { List, ListItem, ListItemText } from '@mui/material';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { https as makeHttps } from "../auth/AuthUser";
import { formatDistanceToNow } from 'date-fns';
import { Link } from 'react-router-dom';

import { VideoRoute, ShortsRoute, handleShareViaAPI } from '../utils/routeUtils';
import VideoListLoading from '../Components/LoadingPages/VideoListLoading';
import VideosNotFound from '../Components/ErrorPages/VideosNotFound';
import ShortsListingLoading from '../Components/LoadingPages/ShortsListingLoading';
import axios from "axios";
import Header from '../Components/Includes/Header';
import SidebarIcon from '../Components/Includes/SidebarIcon';
import { ToastContainer, toast } from 'react-toastify';
import { authHttps, getUser } from "../auth/AuthUser";


const TimeAgo = ({ date }) => {
   // Assuming `date` is a string in the format 'YYYY-MM-DDTHH:mm:ss.sssZ'
   const parsedDate = new Date(date);

   const timeAgo = formatDistanceToNow(parsedDate, { addSuffix: true });

   return <span>{timeAgo}</span>;
};


const formatViewsCount = (count) => {
   if (count < 1000) {
      return count.toString();
   } else if (count < 1000000) {
      return `${(count / 1000).toFixed(1)}K`;
   } else {
      return `${(count / 1000000).toFixed(1)}M`;
   }
};

function Home() {
   const http = makeHttps();
   const http2 = authHttps();

   const [allVideo, setallVideo] = useState([]);

   const [loadingvideos, setloadingvideos] = useState(true);
   const [menu, setMenu] = useState('1');

   const [allVideop, setallVideop] = useState([]);
   const [loadingvideosp, setloadingvideosp] = useState(true);


   const navigate = useNavigate();
   const [photo, setPhoto] = useState('');
   const [showAllVideo, setshowAllVideo] = useState(8);
   const [shortsSlice, setShortsSlice] = useState(5);
   const [showAllShorts, setshowAllShorts] = useState(false);
   const [isDesktop, setIsDesktop] = useState(false);
   // sidebar
   const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
   const [sidebarWidth, setSidebarWidth] = useState(280);
   const [sidebarOpen, setSidebarOpen] = useState(false);

   // save later
   const [watchs, setCheck] = useState(false);
   const [chanelId, setChanelId] = useState('');
   const [videoId, setVideoId] = useState('');

   function calculateShortsSlice() {
      const windowWidth = window.innerWidth;
      // Define your slice widths and corresponding breakpoints
      const sliceWidths = [468, 698, 992, 1105, 1200, 1308, 1400, 1500, 1600]; // Breakpoints
      const numSlices = [2, 3, 4, 3, 4, 4, 4, 6, 8]; // Number of slices for each breakpoint
      // Find the correct number of slices based on the window width
      for (let i = 0; i < sliceWidths.length; i++) {
         if (windowWidth < sliceWidths[i]) {
            return numSlices[i];
         }
      }
      // Default number of slices
      return numSlices[numSlices.length - 1];
   }

   const handleResize = () => {
      const newShortsSlice = calculateShortsSlice();
      // console.log("av start: ", shortsSlice);
      // console.log("update start: ", newShortsSlice);
      if (shortsSlice !== newShortsSlice) {
         // console.log("updated: ", newShortsSlice);
         setShortsSlice(newShortsSlice);
      }
   };

   useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
         window.removeEventListener('resize', handleResize);
      };
   }, []);

   const viewVideo = async (videoId) => {
      await http.post('user/addView', { 'videoId': videoId });

   };


   const getVideo = async (categoryId, event) => {
      try {
         setMenu(event);
         const response = await http.post('user/categoryWiseVideo', { 'categoryId': categoryId });
         if (response.data && response.data.data) {
            setallVideo(response.data.data);
            setPhoto(response.data.data.photo);
         } else {
            console.error('Invalid data structure received from the API:', response.data);
         }
      } catch (error) {
         console.error('Error fetching video categories:', error);
      } finally {
         setloadingvideos(false);
      }


   };

   const fetchVideo = async () => {
      try {
         setloadingvideos(true); // Set loading to true when starting the fetch

         const searchkey = localStorage.getItem('searchkey');
         const endpoint = searchkey ? 'user/searchVideo' : 'user/allVideo';
         const requestData = searchkey ? searchkey : '';

         const response = await http.post(endpoint, { 'searchkey': requestData });

         if (response.data && response.data.data) {
            const filteredData = searchkey ? response.data.data.filter(item => item.type === '1') : response.data.data;
            setallVideo(filteredData);

            // localStorage.removeItem('searchkey');
         } else {
            console.error('Invalid data structure received from the API:', response.data);
         }
      } catch (error) {
         console.error('Error fetching video categories:', error);
      } finally {
         setloadingvideos(false); // Set loading to false regardless of success or error
      }
   };

   useEffect(() => {
      fetchVideo();
   }, []); // Run the effect only once on component mount




   const fetchShortVideo = async () => {
      try {
         setloadingvideosp(true); // Set loading to true when starting the fetch

         const searchkey = localStorage.getItem('searchkey');
         const endpoint = searchkey ? 'user/searchVideo' : 'user/shortVideo';
         const requestData = searchkey ? searchkey : '';

         const response = await http.post(endpoint, { 'searchkey': requestData });

         // console.log('short', response);


         if (response.data && response.data.data) {
            const filteredData = searchkey ? response.data.data.filter(item => item.type === '2') : response.data.data;
            setallVideop(filteredData);
            console.clear();
            console.log('filteredData',filteredData);
            
            localStorage.removeItem('searchkey');
         } else {
            console.error('Invalid data structure received from the API:', response.data);
         }
      } catch (error) {
         console.error('Error fetching video categories:', error);
      } finally {
         setloadingvideosp(false); // Set loading to false regardless of success or error
      }
   };

   useEffect(() => {
      fetchShortVideo();
   }, []); // Run the effect only once on component mount



   const [videoCategories, setVideoCategories] = useState([]);
   const [loadingCategories, setLoadingCategories] = useState(true);

   useEffect(() => {
      // Fetch video categories
      const fetchVideoCategories = async () => {
         try {
            const response = await http.post('user/videoCategory');

            if (response.data) {
               // Make sure response.data is an array before setting it to state
               setVideoCategories(response.data.data);
            } else {
               console.error('Invalid data structure received from the API:', response.data);
            }
         } catch (error) {
            console.error('Error fetching video categories:', error);
         } finally {
            setLoadingCategories(false);
         }
      };

      fetchVideoCategories();
   }, []);



   const handleMenu = async (event) => {
      setMenu(event);
   }

   const checkWatch = async (videoId) => {
      try {
          // const videoId = localStorage.getItem('videoId');
          const response = await http.post('user/checkWatch', { 'videoId': videoId });
          //console.log('checksubs',response);
          if (response.data && response.data.status === 1) {
              setCheck(true);

              //alert('done');
          } else {
              setCheck(false);
          }
      } catch (error) {
          setCheck(false);
      }
  };

   const addWatchLater = async (video) => {

      const response = await http2.post('user/WatchMaster', { 'chanelId': video.chanelId,videoId: video.videoId,type:2});

      if (response.data && response.data.status === 1) {
          checkWatch(videoId);
      }
  };

  const token = localStorage.getItem('token');
// addqueue 
  const handleQueue = async (video) => {
   try {
      
      const response = await http2.post(`/user/videos/${video._id}/add-to-queue`);
      if (response.data && response.data.status == true) {
         const filteredData = allVideo.filter((vid) => vid.videoId !== video.videoId);
         // setAllVideo(filteredData);
         toast.success('Video added to queue successfully!');
      } else {
         console.error('Invalid data structure received from the API:', response.data);
         toast.error('Failed to add video to queue.');
      }
   } catch (error) {
      console.error('Error adding video to queue:', error);
      toast.error('An error occurred while adding the video to the queue.');
   }
}


// not-interested 
const handlenotinterested  = async (video) => {
   try {
      const response = await http2.post(`/user/videos/${video._id}/not-interested`);
      if (response.data && response.data.status == true) {
         const filteredData = allVideo.filter((vid) => vid.videoId !== video.videoId);
         // setAllVideo(filteredData);
         toast.success('Video added to not-interested  successfully!');
      } else {
         console.error('Invalid data structure received from the API:', response.data);
         toast.error('Failed to add video to queue.');
      }
   } catch (error) {
      console.error('Error adding video to not-interested :', error);
      toast.error('An error occurred while adding the video to the not-interested .');
   }
}

// reportvideo
const handleReportVideo  = async (video) => {
   try {
      const response = await http2.post(`/user/videos/${video._id}/report`);
      if (response.data && response.data.status == true) {
         const filteredData = allVideo.filter((vid) => vid.videoId !== video.videoId);
         // setAllVideo(filteredData);
         toast.success('Video added to report successfully!');
      } else {
         console.error('Invalid data structure received from the API:', response.data);
         toast.error('Failed to add video to report.');
      }
   } catch (error) {
      console.error('Error adding video to report:', error);
      toast.error('An error occurred while adding the video to the report.');
   }
}

// not-recommend
const handleNotrecommend  = async (video) => {
   try {
      console.log(video);
      
      const response = await http2.post(`/user/channels/${video._id}/not-recommend`);
      if (response.data && response.data.status == true) {
         const filteredData = allVideo.filter((vid) => vid.videoId !== video.videoId);
         // setAllVideo(filteredData);
         toast.success('Video added to not-recommend successfully!');
      } else {
         console.error('Invalid data structure received from the API:', response.data);
         toast.error('Failed to add video to not-recommend.');
      }
   } catch (error) {
      console.error('Error adding video to queue:', error);
      toast.error('An error occurred while adding the video to the not-recommend.');
   }
}

   const toggleSidebar = () => {
      setSidebarOpen(!sidebarOpen);
      setSidebarCollapsed(!isSidebarCollapsed);
      setSidebarWidth(isSidebarCollapsed ? 280 : 80); // Toggle sidebar width
  };

   return (
      <>
         <Header toggleSidebar={toggleSidebar} />
         <div className="col-xxl-2 col-xl-3 col-lg-3 display991" style={{ width: `${sidebarWidth}px` }}>
         {sidebarOpen ? (
                <SidebarIcon onClick={toggleSidebar} />
            ) : (
                <Sidebar onClick={toggleSidebar} />
            )}
         </div>
         <div className="col-xxl-9 col-xl-9 col-lg-9" style={{ width: isSidebarCollapsed ? '1266px' : 'calc(100% - 280px)' }}>
            {/* <section className="main__tab__slide mb-3">
               <ul className="nav nav-tabs" id="myTabmain" role="tablist">
                  <Swiper
                     navigation={true}
                     modules={[Navigation]}
                     spaceBetween={15}
                     slidesPerView='auto'
                  >

                     {videoCategories && videoCategories.length > 0 && (
                        <>
                           <SwiperSlide onClick={() => handleMenu('1')}>

                              <li className="nav-item" role="presentation">
                                 <button
                                    className={`nav-link ${menu === '1' ? 'active' : ''}`}
                                    onClick={() => fetchVideo()}
                                 >
                                    <span>All</span>
                                 </button>
                              </li>

                           </SwiperSlide>
                           {videoCategories.map((category, index) => (
                              <SwiperSlide key={index} onClick={() => getVideo(category.categoryId, category.category)}>

                                 <li className="nav-item" role="presentation">
                                    <button className={`nav-link ${menu === category.category ? 'active' : ''}`}
                                    >
                                       <span>{category.category}</span>
                                    </button>
                                 </li>

                              </SwiperSlide>
                           ))}
                        </>
                     )}

                  </Swiper>

               </ul>
            </section> */}

            <div className="main__body__wrap left__right__space pb-60">
           
            <div className="section__title">
                  <h2 className='h4'><span className="bi bi-file-play text-theme"></span> Shorts</h2>
               </div>
               <div className={'video-list'}>
                  {loadingvideosp ? (
                     <ShortsListingLoading />
                  ) : allVideop.length > 0 ? (
                     showAllShorts ? (
                        allVideop.map((video, index) => (
                           <ListItem key={index} onClick={() => viewVideo(video.videoId)}>
                              <div className='video-box-single-v with-action'>
                                 <Card className='shorts-card shadow-none'>
                                    <div className='video-box cursor-pointer'>
                                       <Link to={ShortsRoute(video.uniqId)}>
                                          <div>
                                             <CardMedia
                                                component="video"
                                                alt="Video Poster"
                                                poster={video.thumbnail}
                                                className='w-100'
                                                muted
                                             >
                                                <source src={video.url} className='w-100' type="video/mp4" />
                                             </CardMedia>
                                          </div>
                                       </Link>
                                    </div>
                                    <CardContent>
                                       <div className='content-box'>
                                          <div className='row align-items-start g-0'>
                                             <div className='col-11'>
                                                <Typography variant="h5" component="div" className='v-tiltle'>
                                                   <Link to={ShortsRoute(video.uniqId)}>{video.title} </Link>
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                   <Link to={ShortsRoute(video.uniqId)} className='views-time mt-2'>{formatViewsCount(video.view)} views </Link>
                                                </Typography>
                                             </div>
                                             <div className='col-1'>
                                                <div className="dropdown text-end">
                                                   <a href="#" className="d-block caret-none  text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                         <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                                      </svg>
                                                   </a>
                                                   <ul className="dropdown-menu">
                                                      <li><Link className="dropdown-item" href="void:(0)" onClick={() => handleShareViaAPI(video.uniqId)}><i className="me-3 bi bi-share"></i> Share</Link></li>
                                                      {/* <li><Link className="dropdown-item" href="#"><i className="bi bi-send me-3"></i> Feedback</Link></li> */}
                                                   </ul>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </CardContent>
                                 </Card>
                              </div>
                           </ListItem>
                        ))
                     ) : (
                        allVideop.slice(0, shortsSlice).map((video, index) => (
                           <ListItem key={index} onClick={() => viewVideo(video.videoId)}>
                              <div className='video-box-single-v with-action'>
                                 <Card className='shorts-card shadow-none'>
                                    <div className='video-box cursor-pointer'>
                                       <Link to={ShortsRoute(video.uniqId)}>
                                          <div>
                                             <CardMedia
                                                component="video"
                                                alt="Video Poster"
                                                poster={video.thumbnail}
                                                className='w-100'
                                                muted
                                             >
                                                <source src={video.url} className='w-100' type="video/mp4" />
                                             </CardMedia>
                                          </div>
                                       </Link>
                                    </div>
                                    <CardContent>
                                       <div className='content-box'>
                                          <div className='row align-items-start g-0'>
                                             <div className='col-11'>
                                                <Typography variant="h5" component="div" className='v-tiltle'>
                                                   <Link to={ShortsRoute(video.uniqId)}>{video.title} </Link>
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                   <Link to={ShortsRoute(video.uniqId)} className='views-time mt-2'>{formatViewsCount(video.view)} views </Link>
                                                </Typography>
                                             </div>
                                             <div className='col-1'>
                                                <div className="dropdown text-end">
                                                   <a href="#" className="d-block caret-none  text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                         <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                                      </svg>
                                                   </a>
                                                   <ul className="dropdown-menu">
                                                      <li><Link className="dropdown-item" href="void:(0)" onClick={() => handleShareViaAPI(video.uniqId)}><i className="me-3 bi bi-share"></i> Share</Link></li>
                                                      {/* <li><Link className="dropdown-item" href="/login"><i className="bi bi-send me-3"></i> Feedback</Link></li> */}
                                                   </ul>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </CardContent>
                                 </Card>
                              </div>
                           </ListItem>
                        ))
                     )


                  ) : (
                     <></>
                  )}

               </div>
               {allVideop && allVideop.length > 5 && !loadingvideosp && (
                  <div className='row'>
                     <div className='col-12 d-flex justify-content-center align-items-center'>
                        <div className='button-inner d-flex justify-content-center align-items-center'>
                           <button variant="primary" onClick={() => setshowAllShorts(showAllShorts !== true)} className="show-more-btn btn btn-secondary text-light">
                              {showAllShorts ?
                                 <>Show Less <i className='bi bi-chevron-up'></i></>
                                 :
                                 <>Show More <i className='bi bi-chevron-down'></i></>
                              }
                           </button>
                        </div>
                     </div>
                  </div>
               )}
               <div className='row g-3 row-cols-lg-3 row-cols-xl-3 row-cols-md-2 row-cols-sm-2'>
                  {loadingvideos ? (
                     <VideoListLoading />
                  ) : allVideo.length > 0 ? (
                     allVideo.slice(0, showAllVideo).map((video, index) => (
                        <div className='col'>
                           <div className='video-box-single-v with-action' onClick={() => viewVideo(video.videoId)}>

                              <Card className='shadow-none h-194-video'>

                                 <div className='video-box cursor-pointer'>
                                    <Link to={VideoRoute(video.uniqId)}>
                                       {/*--<span className='duration-time'>11:11:11</span>--*/}
                                       <div>
                                          <CardMedia
                                             component="video"
                                             alt="Video Poster"
                                             poster={video.thumbnail}
                                             className='w-100'
                                             muted
                                          >
                                             <source src={video.url} className='w-100' type="video/mp4" />
                                          </CardMedia>
                                       </div>

                                    </Link>
                                 </div>
                                 <CardContent>
                                    <div className='content-box'>
                                       <div className='row align-items-start g-0'>
                                          <div class="col-2">
                                             <Link to={'/@' + video.handel}>
                                                <div className='channel-thumbnail'>

                                                   {
                                                      video.channelIcon ? (
                                                         <img src={video.channelIcon} className="rounded-circle  img-fluid" />
                                                      ) : (
                                                         <img src={profilePic} className="rounded-circle  img-fluid" />
                                                      )}
                                                </div>
                                             </Link>
                                          </div>
                                          <div className='col-9'>
                                             <Typography variant="h5" component="div" className='v-tiltle'>
                                                {/* <Link href='/watch'>{video.title}</Link> */}

                                                <Link to={VideoRoute(video.uniqId)}> {video.title}</Link>
                                             </Typography>
                                             <Typography variant="body2" color="text.secondary">
                                                <Link to={'/@' + video.handel} className='channel-name mt-2'>{video.channelName}</Link>
                                                <Link to={VideoRoute(video.uniqId)} className='views-time'>{formatViewsCount(video.view)} views <span>.</span> {<TimeAgo date={video.createdAt} />}</Link>
                                             </Typography>
                                          </div>
                                          <div className='col-1'>
                                             <div className="dropdown text-end">
                                                <a href="#" className="d-block caret-none  text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                   <i class="bi bi-three-dots-vertical"></i>
                                                </a>
                                                <ul className="dropdown-menu">
                                                   <li><Link className="dropdown-item" href="void:(0)"  onClick={() => handleQueue(video)}><i class="bi bi-bar-chart-steps me-3"></i>Add to queue</Link></li>
                                                   <li><Link className="dropdown-item" href="void:(0)"  onClick={() => addWatchLater(video)} ><i className="me-3 bi bi-clock-history"></i> Save to Watch Later</Link></li>
                                                   {/* <li><Link className="dropdown-item" href="/login"><i class="bi bi-plus-square me-3"></i> Save to Playlist</Link></li> */}
                                                   <li><Link className="dropdown-item" href="/login"><i class="bi bi-download me-3"></i> Download</Link></li>
                                                   <li><Link className="dropdown-item" href="void:(0)" onClick={() => handleShareViaAPI(video.uniqId)}><i className="me-3 bi bi-share"></i> Share</Link></li>
                                                   <li><hr className="dropdown-divider" /></li>
                                                   <li><Link className="dropdown-item" href="void:(0)"  onClick={() => handlenotinterested(video)}><i className="me-3 bi bi-ban"></i>  Not Interested </Link></li>
                                                   <li><Link className="dropdown-item" href="void:(0)" onClick={() => handleNotrecommend(video)}><i class="bi bi-dash-circle me-3"></i>  Don't Recomended Channel </Link></li>
                                                   <li><Link className="dropdown-item" href="void:(0)" onClick={() => handleReportVideo(video)}><i className="me-3 bi bi-flag"></i>  Report </Link></li>
                                                </ul>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </CardContent>
                              </Card>
                           </div>
                        </div>
                     ))
                  ) : (
                     <VideosNotFound />
                  )}
               </div>
               {allVideo && allVideo.length > 5 && !loadingvideos && (
                  <div className='row'>
                     <div className='col-12 d-flex justify-content-center align-items-center'>
                        <div className='button-inner d-flex justify-content-center align-items-center'>
                           <button variant="primary" onClick={() => setshowAllVideo(showAllVideo == 5 && allVideo.length > 5 ? allVideo.length : 5)} className="show-more-btn btn btn-secondary text-light">
                              {showAllVideo > 5 ?
                                 <>Show Less <i className='bi bi-chevron-up'></i></>
                                 :
                                 <>Show More <i className='bi bi-chevron-down'></i></>
                              }
                           </button>
                        </div>
                     </div>
                  </div>
               )}
            </div>
         </div>
         <ToastContainer style={{ zIndex: '99999999' }} />
      </>
   )
}
export default Home
