import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Sidebar from "../../Components/Includes/Sidebar";
import Header from "../../Components/Includes/Header";
import SidebarIcon from "../../Components/Includes/SidebarIcon";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { authHttps, getUser } from "../../auth/AuthUser";

function WithdrawList() {
    const http = authHttps(); // Authentication call
    let userData = getUser(); // Getting user data

    // Sidebar states
    const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(280);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const [filterText, setFilterText] = useState(''); // Search input text
    const [loading, setLoading] = useState(false); // Loading state
    const [withdrawMaster, setWithdrawMaster] = useState([]); // Main data state

    const [withdrawAmount, setWithdrawAmount] = useState(''); // Withdrawal amount for modal

    const [creatorMaster, setCreatorMaster] = useState([]); // Main data state
    const [totalEarningsSum, setTotalEarningsSum] = useState(0); // Total earnings state
    const [totalApprovedWithdrawSum, setTotalApprovedWithdrawSum] = useState(0); // Total approved withdrawals
    const [pendingAmount, setPendingAmount] = useState(0); // Pending amount


    // Filter items based on search text
    const filteredItems = withdrawMaster.filter(item =>
        item.amount.toString().includes(filterText)
    );

    // Handle search input change
    const handleSearch = (event) => {
        setFilterText(event.target.value);
    };

    useEffect(() => {
        fetchWithdrawData();
    }, []);

    useEffect(() => {
        setPendingAmount(totalEarningsSum - totalApprovedWithdrawSum); // Calculate pending amount
    }, [totalEarningsSum, totalApprovedWithdrawSum]);


    // Fetch withdraw data
    const fetchWithdrawData = async () => {
        setLoading(true); // Show loading state
        try {
            const response = await http.get('user/request_withdraw');
            const result = response.data;
            if (result && result.success === true && Array.isArray(result.data)) {
                setWithdrawMaster(result.data); // Setting the withdraw list from API response
                const approvedTotal = result.data
                    .filter(item => item.status === 'approved')
                    .reduce((acc, item) => acc + item.amount, 0);
                setTotalApprovedWithdrawSum(approvedTotal);
            } else {
                toast.error('Failed to fetch withdrawal data.');
            }
        } catch (error) {
            console.error('Error fetching withdrawal data:', error);
            toast.error('Insufficient balance for the requested withdrawal amount.');
        } finally {
            setLoading(false);
        }
    };

    // Handle form submission to request withdrawal
    const handleWithdrawSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await http.post('user/request_withdraw', {
                amount: withdrawAmount,
            });
            if (response.data.success) {
                toast.success('Withdrawal request submitted successfully.');
                fetchWithdrawData(); // Refresh the list
            } else {
                toast.error('Insufficient balance for the requested withdrawal amount.');
            }
        } catch (error) {
            toast.error('Insufficient balance for the requested withdrawal amount');
        }
    };

    // Fetch video creators data
    const fetchCreatorData = async () => {
        setLoading(true); // Show loading state
        try {
            const response = await http.post('user/allVideoAds');
            const result = response.data;
            if (result && result.status === 1 && Array.isArray(result.data)) {
                setCreatorMaster(result.data);
                const total = result.data.reduce((acc, item) => acc + (item.totalEarnings || 0), 0);
                setTotalEarningsSum(total);
            } else {
                toast.error('Failed to fetch video creators.');
            }
        } catch (error) {
            console.error('Error fetching video creators:', error);
            toast.error('Error fetching video creators.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCreatorData();
    }, []);

    // Columns definition for the DataTable
    const columns = [
        {
            name: 'S. No.',
            selector: (row, index) => index + 1,
            sortable: false,
            width: '70px',
        },
        {
            name: 'Amount',
            selector: row => `₹${row.amount}.00`,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => new Date(row.createdAt).toLocaleDateString(),
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            cell: row => (
                <span
                    className={`badge ${row.status === 'approved'
                        ? 'bg-success'
                        : row.status === 'pending'
                            ? 'bg-warning'
                            : 'bg-danger'
                        }`}
                >
                    {row.status}
                </span>
            ),
        },
    ];

    // Toggle Sidebar
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
        setSidebarCollapsed(!isSidebarCollapsed);
        setSidebarWidth(isSidebarCollapsed ? 280 : 80); // Toggle sidebar width
    };

    return (
        <>
            <Header toggleSidebar={toggleSidebar} />
            <div className="col-xxl-2 col-xl-3 col-lg-3 display991" style={{ width: `${sidebarWidth}px` }}>
                {sidebarOpen ? (
                    <SidebarIcon onClick={toggleSidebar} />
                ) : (
                    <Sidebar onClick={toggleSidebar} />
                )}
            </div>
            <div className="col-xxl-9 col-xl-9 col-lg-9" style={{ width: isSidebarCollapsed ? '1266px' : 'calc(100% - 280px)' }}>
                <div className="content-registration pt-3 bg-dark">
                    <div className="container">
                        <div className="p-1">
                            <div className="p-1">
                                <div className='row g-3'>
                                    <div className="col-lg-12">
                                        <div className=''>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <h4 className="mb-2 ">Withdraw List</h4>
                                                </div>
                                                <div className='col-md-9'>
                                                    <div className='row'>
                                                        <div className='col-md-3 mb-2'>
                                                            <span>
                                                                <strong> Earning: <span style={{ backgroundColor: '#1eafe4' }} className="badge rounded-pill fs-6">₹{totalEarningsSum}.00</span></strong>
                                                            </span>
                                                        </div>
                                                        <div className='col-md-3 mb-2'>
                                                            <span>
                                                                <strong> Withdrawal: <span style={{ backgroundColor: '#1eafe4' }} className="badge rounded-pill fs-6">₹{totalApprovedWithdrawSum}.00</span></strong>
                                                            </span>
                                                        </div>
                                                        <div className='col-md-3 mb-2'>
                                                            <span>
                                                                <strong> Pending: <span style={{ backgroundColor: '#1eafe4' }} className="badge rounded-pill fs-6">₹{pendingAmount}.00</span></strong>
                                                            </span>
                                                        </div>
                                                        <div className='col-md-3 mb-2 text-end'>
                                                            <span className='ms-2'>
                                                                <button className='btn btn-secondary text-white' data-bs-toggle="modal"
                                                                    data-bs-target="#withdrawModal"> Withdrawal Request </button>
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            {/* <h4 className="mb-2 ">Withdraw List</h4>
                                            <span style={{ marginLeft: 'auto' }}>
                                                <strong>Total Earning: <span style={{backgroundColor:'#1eafe4'}} className="badge rounded-pill fs-6">₹{totalEarningsSum}.00</span></strong>
                                            </span>
                                            <span style={{ marginLeft: '20px' }}>
                                                <strong>Total Withdrawal: <span style={{backgroundColor:'#1eafe4'}} className="badge rounded-pill fs-6">₹{totalApprovedWithdrawSum}.00</span></strong>
                                            </span>
                                            <span className='ms-2'>
                                                <button className='btn btn-secondary text-white' data-bs-toggle="modal"
                                                    data-bs-target="#withdrawModal"> Withdrawal Request </button>
                                            </span> */}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="col-lg-12">
                                        <div className="mb-3 d-flex justify-content-between">
                                            <input
                                                type="text"
                                                placeholder="Search by Amount"
                                                value={filterText}
                                                onChange={handleSearch}
                                                className="form-control"
                                                style={{ width: '200px' }}
                                            />
                                        </div>
                                        {loading ? (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                                                <p>Loading...</p>
                                            </div>
                                        ) : (
                                            <DataTable
                                                columns={columns}
                                                data={filteredItems}
                                                pagination
                                                highlightOnHover
                                                responsive
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal Add New Withdraw Request */}
            <div
                className="modal fade"
                id="withdrawModal"
                tabIndex={-1}
                aria-labelledby="withdrawModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="withdrawModalLabel">
                                Withdraw Amount
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleWithdrawSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="withdrawAmount" className="form-label">
                                        Amount (₹)
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="withdrawAmount"
                                        value={withdrawAmount}
                                        onChange={(e) => setWithdrawAmount(e.target.value)}
                                        required
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary">
                                    Request Withdrawal
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer />
        </>
    );
}

export default WithdrawList;
